import { BEIGE_300, GRAY_700, GRAY_900, SangBleuFontFamily } from 'constants/styling/theme'
import { Box, Stack, ThemeProvider, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'

import { FC } from 'react'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useAuth0 } from 'utils/auth'
import { useResendChangeEmail } from 'dataQueries'

interface Props {
  handleResendEmail: () => void
}

/**
 * Displays custom Verify Email component during onboarding flow.
 * 
 * @example <OnboardingPageVerifyEmail />
 */
export const OnboardingPageVerifyEmail: FC<Props> = ({ handleResendEmail }) => {
  const { t } = useTranslation(['unverified_user'])
  const { user } = useAuth0()
  const resendChangeEmail = useResendChangeEmail()

  return (
    <ThemeProvider theme={redesignMUITheme}>
      <Box sx={{ width: '100%', height: '100vh', display: 'flex', backgroundColor: BEIGE_300 }}>
        <Stack direction="column" gap={2} alignItems="center" textAlign="center" margin='24rem auto' width='36rem'>

          <Typography fontSize='3rem' fontFamily={SangBleuFontFamily} color={GRAY_900}>{t('verify_email_title')}</Typography>

          <Typography variant='text-sm' color={GRAY_700} >

            <Trans t={t} i18nKey="verify_email_description_top" values={{ email: user?.email }}>
              <Typography variant='text-sm' fontWeight={600} color={GRAY_900}></Typography>
            </Trans>

            <Stack marginTop='1.2rem'>
              <Trans t={t} i18nKey="verify_email_description_bottom">
                <Typography
                  sx={{ cursor: 'pointer' }}
                  variant='text-sm'
                  fontWeight={600}
                  color={GRAY_900}
                  onClick={!resendChangeEmail.isPending ? handleResendEmail : undefined}
                />
              </Trans>
            </Stack>

          </Typography>

        </Stack>
      </Box>
    </ThemeProvider>
  )
}
