import { FC } from 'react'
import { PowerOfAttorneyUpload } from 'components/common/PowerOfAttorneyUpload'
import { Stack } from '@mui/material'
import { useTargetOrderUser } from '../../_main/contexts'

export const PowerOfAttorneyStepController: FC = () => {
  const { targetUser } = useTargetOrderUser()

  return (
    <Stack direction="column" gap={2} alignItems="stretch" justifyContent="flex-start">

      <PowerOfAttorneyUpload
        sampleUrl="https://pdfobject.com/pdf/sample.pdf"
        uploadSlot={null}
        variant="owners"
      />


      {targetUser.requiresRepresentativePowerOfAttorney &&
        <PowerOfAttorneyUpload
          sampleUrl="https://pdfobject.com/pdf/sample.pdf"
          uploadSlot={null}
          variant="representatives"
        />
      }

    </Stack>
  )
}
