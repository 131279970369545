import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'
import { RegistrationStepKey } from '../SignupRegistration.controller'
import { SignupCreateWorkspace } from './SignupCreateWorkspace.component'
import { useSignupRegistration } from '../SignupRegistration.context'

export const SignupCreateWorkspaceStep: FC = () => {

  const { isCreateWorkspaceStepValid } = useSignupRegistration()

  return (
    <DynamicFlowStep
      stepKey={RegistrationStepKey.CREATE_WORKSPACE}
      isValid={isCreateWorkspaceStepValid}
    >
      <SignupCreateWorkspace />
    </DynamicFlowStep>
  )
}
