import { BEIGE_300, GRAY_700, GRAY_900, MOBILE_VIEW_QUERY, SangBleuFontFamily } from 'constants/styling/theme'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import { Country } from 'constants/country'
import { Language } from 'translations/Language'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIInputField } from 'components/common/MUIInputField'
import { SignupHowDidYouHearAnswers } from 'constants/signupQuestions'
import { timezoneNames } from 'utils/time'
import { useCallback } from 'react'
import { useDynamicFlowController } from 'components/common/DynamicFlow'
import { useSignupRegistration } from '../SignupRegistration.context'
import { useTranslation } from 'react-i18next'

export const SignupCreateAccount: React.FC = () => {
  const { t } = useTranslation(['signup', 'country', 'language', 'common'])
  const flowCtrl = useDynamicFlowController()
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    country,
    setCountry,
    timezone,
    setTimezone,
    language,
    setLanguage,
    howDidYouHear,
    setHowDidYouHear,
    isAccountInfoStepValid
  } = useSignupRegistration()

  const onNextClick = useCallback(() => {
    const currentStep = flowCtrl.currentNode
    if (!currentStep?.next) return

    flowCtrl.setHead(currentStep.next)

    // Omit flowCtrl dependency to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowCtrl.currentNode?.next])

  return (
    <Stack padding={isMobileView ? '8rem 0' : '8rem'} width="36rem">

      <Stack gap="1.6rem" alignItems="center" marginBottom="4rem">
        <Typography variant="h6" color={GRAY_900} fontWeight={500} fontFamily={SangBleuFontFamily}>{t('account_information')}</Typography>
        <Typography variant="text-sm" color={GRAY_700}>{t('personal_information')}</Typography>
      </Stack>

      <Stack gap="1.6rem" marginBottom="4rem">
        <Stack gap=".8rem" flexDirection="row" justifyContent="space-between">
          <MUIInputField
            label={t('common:first_name')}
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
          />

          <MUIInputField
            label={t('common:last_name')}
            value={lastName}
            onChange={e => setLastName(e.target.value)}
          />
        </Stack>

        {/* Country dropdown */}
        <MUIDropdown
          button={(isOpen, action) => (
            <MUIDropdownInput
              readOnly
              label={t('country')}
              type={DropdownInputTypes.SELECTOR}
              value={country ? t(`country:${country}`) : country}
              onClick={action}
            />
          )}
        >
          <Box height="30rem">
            {Object.values(Country)?.map(countryKey => (
              <MUIDropdownItem key={countryKey} onClick={() => setCountry(countryKey)}>
                <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                  {t(`country:${countryKey}`, countryKey)}
                </Typography>
              </MUIDropdownItem>
            ))}
          </Box>
        </MUIDropdown>

        {/* Language dropdown */}
        <MUIDropdown
          button={(isOpen, action) => (
            <MUIDropdownInput
              readOnly
              label={t('language')}
              type={DropdownInputTypes.SELECTOR}
              value={t(`language:${language.toUpperCase()}`, language)}
              onClick={action}
            />
          )}
        >
          {Object.values(Language)?.map(lang => (
            <MUIDropdownItem key={lang} onClick={() => setLanguage(lang)}>
              <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                {t(`language:${lang.toUpperCase()}`, lang)}
              </Typography>
            </MUIDropdownItem>
          ))}
        </MUIDropdown>

        {/* Timezone dropdown */}
        <MUIDropdown
          button={(isOpen, action) => (
            <MUIDropdownInput
              readOnly
              label={t('timezone')}
              type={DropdownInputTypes.SELECTOR}
              value={timezone}
              onClick={action}
            />
          )}
        >
          <Box height="40rem">
            {timezoneNames.map(tzn => (
              <MUIDropdownItem key={tzn} onClick={() => setTimezone(tzn)}>
                <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                  {tzn}
                </Typography>
              </MUIDropdownItem>
            ))}
          </Box>
        </MUIDropdown>

        {/* How did you here about us dropdown */}
        <MUIDropdown
          button={(isOpen, action) => (
            <MUIDropdownInput
              readOnly
              label={t('how_did_you_hear_about_us.section_title')}
              type={DropdownInputTypes.SELECTOR}
              value={howDidYouHear ? t(`how_did_you_hear_about_us.answers.${howDidYouHear}`) : howDidYouHear}
              onClick={action}
            />
          )}
        >
          {Object.values(SignupHowDidYouHearAnswers).map(answer => (
            <MUIDropdownItem key={answer} onClick={() => setHowDidYouHear(answer)}>
              <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                {t(`how_did_you_hear_about_us.answers.${answer}`)}
              </Typography>
            </MUIDropdownItem>
          ))}
        </MUIDropdown>

      </Stack>

      {!isMobileView &&
        <Stack alignItems="flex-end">
          <MUIButton
            size="lg"
            endIcon={<ArrowForwardRoundedIcon fontSize="large" />}
            disabled={!isAccountInfoStepValid}
            onClick={() => onNextClick()}
          >
            {t('continue')}
          </MUIButton>
        </Stack>
      }

      {isMobileView &&
        <Stack gap=".4rem" padding="2.4rem 1.6rem" flexDirection="row" position="fixed" bottom="0" left="0" width="100%" boxSizing="border-box" sx={{ background: BEIGE_300 }}>
          <MUIButton
            fullWidth
            size="lg"
            endIcon={<ArrowForwardRoundedIcon fontSize="large" />}
            disabled={!isAccountInfoStepValid}
            onClick={() => onNextClick()}
          >
            {t('continue')}
          </MUIButton>
        </Stack>
      }

    </Stack>
  )
}
