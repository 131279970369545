import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'
import { RegistrationStepKey } from '../SignupRegistration.controller'
import { SignupCreateAccount } from './SignupCreateAccount.component'
import { useSignupRegistration } from '../SignupRegistration.context'

export const SignupCreateAccountStep: FC = () => {

  const { isAccountInfoStepValid } = useSignupRegistration()

  return (
    <DynamicFlowStep
      stepKey={RegistrationStepKey.CREATE_ACCOUNT}
      isValid={isAccountInfoStepValid}
    >
      <SignupCreateAccount />
    </DynamicFlowStep>
  )
}
