import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { BEIGE_500, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { FloorPlanSelectionType, useFloorPlanConfig } from '../_main/FloorPlanConfigStep.context'

import AddIcon from '@mui/icons-material/Add'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import { FloorPlanTemplates } from '../FloorPlanTemplates'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDivider } from 'components/common/MUIDivider'
import React from 'react'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useFloorPlanLogo } from '../_main/FloorPlanLogo.context'
import { usePurchaseFlowConfig } from 'components/pages/PurchaseFlow/_main/contexts'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * FloorPlanTemplateTypeSelection displays whether the user wants to select one of their saved templates or create a new one.
 * 
 * @example
 * <FloorPlanTemplateTypeSelection />
 */
export const FloorPlanTemplateTypeSelection: React.FC = () => {
  const { t } = useTranslation(['purchase_flow', 'common'])
  const { isTemplateLogoUploading } = useFloorPlanLogo()
  const { setFloorPlanTemplateType, resetConfiguration } = useFloorPlanConfig()
  const { selectedCategory } = usePurchaseFlowConfig()

  const handleCreateTemplate = () => {
    if (isTemplateLogoUploading) return

    resetConfiguration()
    setFloorPlanTemplateType(FloorPlanSelectionType.CUSTOM)

    logAnalyticsEvent(AnalyticsEvent.FLOOR_PLAN_CREATE_SELECTED, { category: selectedCategory })
  }

  return (
    <BorderBoxWrapper backgroundColor={BEIGE_500} padding="2.4rem">

      <Stack alignItems="center" position="relative">

        <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
          {t('floor_plan_step.templates.title')}
        </Typography>

        <FloorPlanTemplates />

      </Stack>

      <MUIDivider margin={16}>
        <Typography variant='text-sm' color={GRAY_700}>
          {t('common:Or')}
        </Typography>
      </MUIDivider>

      <Stack alignItems="center">
        <MUIButton
          type="secondaryBorder"
          disabled={isTemplateLogoUploading}
          onClick={() => handleCreateTemplate()}
          startIcon={<AddIcon fontSize='large' />}
        >
          {t('floor_plan_step.templates.new_template')}
        </MUIButton>
      </Stack>

    </BorderBoxWrapper>
  )
}
