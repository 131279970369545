import { APIRequestState, Endpoint, RoleMimetype } from 'constants/API'
import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { actionTypeTupleTest, generalFetch } from 'redux/Helpers'
import { all, takeEvery } from 'typed-redux-saga'
import { EntityKeys, QueryType } from 'utils/reactQuery'

import { put } from 'redux-saga/effects'
import API from 'utils/API/API'
import { ActionSelectVisualsForStaging } from './SelectVisualsForStaging.actions'

/** Saga which selects visuals for staging */
export function* selectVisualsForStagingSaga(receivedAction: ActionSelectVisualsForStaging) {

  const { assignmentId, stagingAssignmentId, stagingConfiguration } = receivedAction.payload

  const url = Endpoint.SELECT_VISUALS_FOR_STAGING
    .replace('{assignmentId}', encodeURI(assignmentId.toString()))

  const action: ActionSelectVisualsForStaging = yield generalFetch(ActionTypeAPIData.SELECT_VISUALS_FOR_STAGING, () => API.post(
    url,
    {
      ...stagingConfiguration
    },
    {
      params: {
        stagingAssignmentId,
      },
      headers: {
        Accept: RoleMimetype.CLIENT, // TODO: Fix on BE so we can send proper accept header
      },
    },
    {
      endpoint: Endpoint.SELECT_VISUALS_FOR_STAGING,
    }
  ))

  const selectVisualsForStagingAction: ActionSelectVisualsForStaging = {
    ...action,
    payload: {
      ...receivedAction.payload,
      ...action.payload,
    }
  }

  yield put(selectVisualsForStagingAction)
  if (selectVisualsForStagingAction.payload.request.state !== APIRequestState.OK) return

  // We need to invalidate order to properly get assignment stages
  receivedAction.queryClient?.invalidateQueries({ queryKey: [EntityKeys.ORDER, QueryType.GET_ONE, { orderId: receivedAction.payload.dealId }] })
}

/** Watcher of select visuals for staging saga */
export function* selectVisualsForStagingWatcher() {
  yield all([
    takeEvery(
      (action: ActionSelectVisualsForStaging) => actionTypeTupleTest(
        action,
        [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.SELECT_VISUALS_FOR_STAGING]
      ),
      selectVisualsForStagingSaga
    )
  ])
}
