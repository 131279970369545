import { Box, Stack } from '@mui/material'
import { CORAL_100, CORAL_600 } from 'constants/styling/theme'
import { FC, useCallback, useEffect, useMemo, useRef } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ArrowDownward } from '@mui/icons-material'
import { BKBNThemeWrapper } from 'components/common/BKBNThemeWrapper'
import Button from 'components/common/Button/Button'
import { Clock } from 'components/common/Clock/Clock.component'
import { MUIDatePicker } from 'components/common/MUIDatePicker'
import { Nullable } from 'models/helpers'
import { QueryStatus } from 'components/common/QueryStatus'
import { ResponsiveDrawer } from 'components/common/ResponsiveDrawer'
import styles from './UnavailabilityDrawer.module.sass'
import { supportEmailHref } from 'constants/contacts'
import { useTimezone } from 'components/contexts/timezone.context'
import { useUnavailabilityDrawer } from './UnavailabilityDrawer.context'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * Uses Responsive drawer to render slide-out form for editing / adding CT unavailability
 * 
 * DEPENDENCIES:
 * - UnavailabilityDrawerContext
 * 
 * @example <UnavailabilityDrawer />
 */
export const UnavailabilityDrawer: FC = () => {

  const { t } = useTranslation(['unavailability_drawer', 'actions', 'common'])
  const { baseUserData } = useUserData()
  const { userTimezone, isBrowserAndUserSameOffset } = useTimezone()

  const {
    unavailabilityId,
    fromDate,
    toDate,
    fromError,
    toError,
    isFormValid,
    defaultFromDate,
    defaultToDate,
    isDrawerOpen,
    doesUnavailabilityClash,
    createUnavailability,
    updateUnavailability,
    setFromDate,
    setToDate,
    closeUnavailabilityDrawer,
  } = useUnavailabilityDrawer()

  const actionMutation = useMemo(() => !!unavailabilityId ? updateUnavailability : createUnavailability, [createUnavailability, unavailabilityId, updateUnavailability])

  const handleSubmit = useCallback((isEdit = false) => {
    if (!isFormValid) return
    if (!baseUserData?.id) return

    if (isEdit && !!unavailabilityId) updateUnavailability.mutate({ id: unavailabilityId, from: fromDate, to: toDate })

    if (!isEdit) createUnavailability.mutate({ from: fromDate, to: toDate, creativeId: baseUserData.id })
  }, [baseUserData?.id, createUnavailability, fromDate, isFormValid, toDate, unavailabilityId, updateUnavailability])

  // Automatically close the drawer after action success
  const closeTimeoutRef = useRef<Nullable<number>>(null)
  useEffect(() => {
    if (actionMutation.status !== 'success') return
    if (!!closeTimeoutRef.current) window.clearTimeout(closeTimeoutRef.current)
    closeTimeoutRef.current = window.setTimeout(() => {
      actionMutation.reset()
      closeUnavailabilityDrawer()
      closeTimeoutRef.current = null
    }, 1000)
  }, [actionMutation, closeUnavailabilityDrawer])

  return (
    <BKBNThemeWrapper>
      <ResponsiveDrawer
        isOpen={isDrawerOpen}
        onClose={() => { actionMutation.reset(); closeUnavailabilityDrawer() }}
        title={t(!unavailabilityId ? 'add' : 'edit')}
        actionsSlot={
          <>

            {doesUnavailabilityClash &&
              <Box sx={{
                color: CORAL_600,
                background: CORAL_100,
                borderRadius: '6px',
                padding: '1.5rem',
                fontSize: '1.3rem',
                lineHeight: 1.3,
                marginBottom: '2rem',
                width: '100%'
              }}>
                <Trans t={t} i18nKey="clashing_assignment">
                  <a href={supportEmailHref()}>&nbsp;</a>
                </Trans>
              </Box>
            }

            <Box flex="0 0 100%">
              <QueryStatus query={actionMutation} spaceBottomRem={2} onPurge={() => actionMutation.reset()} />
            </Box>

            <Button
              type="secondary nobackground noborder"
              onClick={() => { actionMutation.reset(); closeUnavailabilityDrawer() }}
            >
              {t(actionMutation.isSuccess ? 'actions:Close' : 'actions:Cancel')}
            </Button>

            {!actionMutation.isSuccess &&
              <Button
                disabled={!isFormValid}
                onClick={() => handleSubmit(!!unavailabilityId)}
              >
                {t(!unavailabilityId ? 'add' : 'edit')}
              </Button>
            }

          </>
        }
      >
        <div className={styles.unavailabilityDrawer}>

          {!isBrowserAndUserSameOffset &&
            <Stack direction="row" gap={2} alignItems="center" className={styles.worldClock} justifyContent="space-between" sx={{ marginBottom: 4 }}>
              <Box flex="1 1 45%">
                <h4>{t('common:time_in_profile_tz', { timezoneName: userTimezone, offset: '' })}</h4>
                <Clock className={styles.clock} />
              </Box>
            </Stack>
          }

          <Stack alignItems="flex-start" justifyContent="flex-start" spacing={2}>

            <h3>Unavailability range</h3>

            <Box sx={{ width: '100%' }}>

              <MUIDatePicker
                sx={{ width: '100%' }}
                defaultValue={defaultFromDate}
                value={fromDate}
                onChange={(date) => setFromDate(date || defaultFromDate)}
                timeSteps={{ minutes: 15 }}
                timezone={userTimezone}
                disablePast
                format='DD/MM/YYYY HH:mm'
                ampm={false}
                isError={!!fromError}
                errorText={t(`errors.${fromError}`)}
              />

            </Box>

            <ArrowDownward fontSize='large' sx={{ opacity: 0.6, paddingLeft: 1 }} />

            <Box sx={{ width: '100%' }}>

              <MUIDatePicker
                sx={{ width: '100%' }}
                minDateTime={fromDate.clone().add(15, 'minutes')}
                defaultValue={defaultToDate}
                value={toDate}
                format='DD/MM/YYYY HH:mm'
                onChange={(date) => setToDate(date || defaultFromDate)}
                timeSteps={{ minutes: 15 }}
                timezone={userTimezone}
                ampm={false}
                isError={!!toError}
                errorText={t(`errors.${toError}`)}
              />

            </Box>

          </Stack>

        </div>
      </ResponsiveDrawer>
    </BKBNThemeWrapper>
  )
}
