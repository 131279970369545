import { ActionTypeAPIData, ActionTypeAPIEvent } from 'constants/redux'
import { ActionRequest, StatusResponse } from 'models/redux'
import { StagingConfiguration, StagingRequestDTO } from 'models/virtualStaging'

import { QueryClient } from '@tanstack/react-query'
import { APIRequestState } from 'constants/API'
import { APIRequest } from 'models/API'
import { createAPIAction } from 'redux/Helpers'

/** Describes general action for select visuals for staging */
export interface ActionSelectVisualsForStaging extends ActionRequest {
  payload: {
    /** Id of assignment from which photos will be staged */
    assignmentId: string
    /** Id of parent deal */
    dealId: number
    /** Id of assignment which will inherit images for staging */
    stagingAssignmentId: string
    stagingConfiguration: StagingConfiguration
    request: StatusResponse
  }
}

/** Creates an action for selecting visuals for staging */
export function selectVisualsForStaging(
  assignmentId: string,
  dealId: number,
  stagingAssignmentId: string,
  stagingConfiguration: StagingRequestDTO,
  queryClient: QueryClient
): ActionSelectVisualsForStaging {
  return createAPIAction(
    [ActionTypeAPIEvent.FETCH, ActionTypeAPIData.SELECT_VISUALS_FOR_STAGING],
    {
      assignmentId,
      dealId,
      stagingAssignmentId,
      stagingConfiguration,
      request: new APIRequest(APIRequestState.RUNNING),
    },
    queryClient
  )
}

/** Creates an action purging select visuals for staging state */
export function purgeSelectVisualsForStaging(
  assignmentId: string
): ActionSelectVisualsForStaging {
  return createAPIAction([ActionTypeAPIEvent.BEFORE_START, ActionTypeAPIData.SELECT_VISUALS_FOR_STAGING], {
    assignmentId,
    request: new APIRequest(APIRequestState.BEFORE_START),
  })
}
