import { Box, Stack } from '@mui/material'
import { CORAL_100, CORAL_600 } from 'constants/styling/theme'
import { DeclineAssignmentReason, maxDeclineReasonTextCharacters } from 'constants/assignment'
import { FC, Fragment, useCallback, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useCheckCTUnavailabilityConflicts_, useDeclineAssignment } from 'dataQueries'

import Button from 'components/common/Button/Button'
import { ISODateString } from 'models/misc'
import { MUIDatePicker } from 'components/common/MUIDatePicker'
import Modal from 'components/common/Modals/Modal/Modal'
import { Nullable } from 'models/helpers'
import { ProductDTO } from 'models/product'
import { ProductKind } from 'constants/product'
import { QueryStatus } from 'components/common/QueryStatus'
import TransitionAppear from 'components/common/TransitionAppear/TransitionAppear'
import moment from 'moment-timezone'
import { standardEnterEffect } from 'utils/animations'
import styles from './DeclineAssignmentPopup.module.sass'
import { supportEmailHref } from 'constants/contacts'
import { useDebouncedEffect } from 'utils/helpers'
import { useTimezone } from 'components/contexts/timezone.context'

enum DeclineUnavailableOptions {
  ONLY_THIS_TIME = 'ONLY_THIS_TIME',
  NEW_UNAVAILABILITY = 'NEW_UNAVAILABILITY'
}

const productKindsUnlockingDeclineDrone = new Set([
  ProductKind.DRONE_PHOTO,
  ProductKind.STANDARD_DRONE_VIDEO,
  ProductKind.PRESTIGE_GROUND_AND_DRONE_VIDEO,
  ProductKind.STANDARD_GROUND_AND_DRONE_VIDEO,
  ProductKind.GROUND_PHOTO_PRESTIGE,
])

interface Props {
  assignmentId: string
  product: Nullable<ProductDTO>
  shootingStart: Nullable<ISODateString>
  shootingDuration: number
  isOpen: boolean
  onClose: () => void
}

/**
 * Popup for selecting reason of assignment decline
 * @example
 *  <DeclineAssignmentPopup
 *    product={assignment.products[0]}
 *    assignmentId={assignment.id}
 *    isOpen={true}
 *    onClose={() => setIsOpen(false)}
 *  />
 */
export const DeclineAssignmentPopup: FC<Props> = ({
  product,
  isOpen,
  assignmentId,
  shootingStart,
  shootingDuration,
  onClose,
}) => {
  const { t } = useTranslation(['deal_assignment_card', 'common', 'unavailability_drawer'])
  const { userTimezone } = useTimezone()
  const declineAssignment = useDeclineAssignment()

  const [declineAssignmentReason, setDeclineAssignmentReason] = useState<DeclineAssignmentReason | undefined>(undefined)
  const [declineAssignmentReasonText, setDeclineAssignmentReasonText] = useState<string | ''>('')
  const [declineUnavailableReason, setDeclineUnavailableReason] = useState<DeclineUnavailableOptions | undefined>(DeclineUnavailableOptions.ONLY_THIS_TIME)

  const declineAssignmentReasons = useMemo(() => {
    const reasons = Object.values(DeclineAssignmentReason)

    if (!!product && productKindsUnlockingDeclineDrone.has(product.kind)) {
      // Returns all the declining reasons
      return reasons
    }
    // Excludes the drone declining reason
    return reasons.filter(
      reason => reason !== DeclineAssignmentReason.NOT_POSSIBLE_TO_FLY_IN_THIS_ZONE
    )
  }, [product])

  const isMaxDeclineReasonTextCharacters = useMemo(() => declineAssignmentReasonText.length === maxDeclineReasonTextCharacters, [declineAssignmentReasonText])

  const handleReset = useCallback(() => {
    setDeclineAssignmentReason(undefined)
    setDeclineAssignmentReasonText('')
    setDeclineUnavailableReason(DeclineUnavailableOptions.ONLY_THIS_TIME)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const baseAvailableDate = useMemo(() => moment().add(1, 'minute').seconds(0).milliseconds(0), [isOpen])
  const defaultFromDate = useMemo(() => shootingStart ? moment(shootingStart) : baseAvailableDate.clone(), [baseAvailableDate, shootingStart])
  const defaultToDate = useMemo(() => shootingStart ? moment(shootingStart).add(shootingDuration, 'minutes') : baseAvailableDate.clone(), [baseAvailableDate, shootingDuration, shootingStart])

  const [fromDate, setFromDate] = useState(defaultFromDate)
  const [toDate, setToDate] = useState(defaultToDate.clone())

  const fromError = useMemo(() => {
    if (!fromDate) return 'required'
    if (fromDate.isBefore(baseAvailableDate)) return 'in_past'
    return null
  }, [baseAvailableDate, fromDate])

  const toError = useMemo(() => {
    if (!toDate) return 'required'
    if (!!fromDate && toDate.isSameOrBefore(fromDate)) return 'is_same_or_before'
    return null
  }, [fromDate, toDate])

  // Query actions
  const checkConflicts = useCheckCTUnavailabilityConflicts_(fromDate, toDate)
  const doesUnavailabilityClash = useMemo(() => (checkConflicts.data || []).length > 0, [checkConflicts.data])

  const isFormValid = useMemo(() => {
    if (!!fromError) return false
    if (!!toError) return false
    if (checkConflicts.isFetching) return false
    if (doesUnavailabilityClash) return false
    return true
  }, [checkConflicts, doesUnavailabilityClash, fromError, toError])

  const isConfirmDisabled = useMemo(() => {
    if (!declineAssignmentReason) return true
    if (declineAssignmentReason === DeclineAssignmentReason.OTHER && !declineAssignmentReasonText) return true
    if (
      declineAssignmentReason === DeclineAssignmentReason.NOT_AVAILABLE
      && declineUnavailableReason === DeclineUnavailableOptions.NEW_UNAVAILABILITY
      && !isFormValid
    ) return true
    return false
  }, [declineAssignmentReason, declineAssignmentReasonText, declineUnavailableReason, isFormValid])


  const handleDeclineAssignment = useCallback(() => {

    if (!assignmentId || !declineAssignmentReason) return

    const isOther = declineAssignmentReason === DeclineAssignmentReason.OTHER
    const isNewUnavailability = declineAssignmentReason === DeclineAssignmentReason.NOT_AVAILABLE && declineUnavailableReason === DeclineUnavailableOptions.NEW_UNAVAILABILITY

    if (isNewUnavailability && !isFormValid) return
    if (isOther && !declineAssignmentReasonText) return

    return declineAssignment.mutate({
      assignmentId,
      reason: declineAssignmentReason,
      reasonFreetext: declineAssignmentReason === DeclineAssignmentReason.OTHER ? declineAssignmentReasonText : null,
      unavailabilityInterval: declineAssignmentReason === DeclineAssignmentReason.NOT_AVAILABLE && declineUnavailableReason === DeclineUnavailableOptions.NEW_UNAVAILABILITY
        ? {
          from: fromDate.toISOString(true),
          to: toDate.toISOString(true),
        }
        : null
    })

  }, [assignmentId, declineAssignment, declineAssignmentReason, declineAssignmentReasonText, declineUnavailableReason, fromDate, isFormValid, toDate])

  // Check for unavailability collisions
  useDebouncedEffect(() => {
    if (!isOpen) return
    if (declineAssignmentReason !== DeclineAssignmentReason.NOT_AVAILABLE) return
    if (declineUnavailableReason !== DeclineUnavailableOptions.NEW_UNAVAILABILITY) return
    if (!fromDate || !toDate) return
    if (!fromDate.isValid() || !toDate.isValid()) return
    if (!!fromError || !!toError) return
    checkConflicts.refetch()
  }, [checkConflicts.refetch, isOpen, declineAssignmentReason, declineUnavailableReason, fromDate, toDate, fromError, toError], 300)

  return (
    <Modal
      isOpen={isOpen}
      afterClosed={handleReset}
      title={t('deal_assignment_card:decline_assignment_popup:title')}
      modalContentClassName={styles.modal}
    >
      <h4>{t('deal_assignment_card:decline_reasons:title')}</h4>

      {declineAssignmentReasons.map(reason => {
        return (
          <div key={reason}>
            <label className="checkbox" htmlFor={`decline-reason-${reason}`}>
              <input
                type="radio"
                name="decline-reason"
                id={`decline-reason-${reason}`}
                value={reason}
                onClick={() => setDeclineAssignmentReason(reason)}
              />
              <span className="checkmark"></span>
              <span className="label-after">{t(`deal_assignment_card:decline_reasons:${reason}`)}</span>
            </label>

            {/* OTHER REASON TEXT */}
            <TransitionAppear
              visible={
                reason === DeclineAssignmentReason.OTHER
                && declineAssignmentReason === reason
              }
              onEnter={standardEnterEffect}
            >
              <div className={styles.children}>
                <Stack>
                  <label htmlFor="decline-reason-text">
                    {t('deal_assignment_card:decline_reasons:other_text')}
                  </label>
                  <input
                    type="text"
                    name="decline-reason-text"
                    id="decline-reason-text"
                    value={declineAssignmentReasonText}
                    onChange={e => setDeclineAssignmentReasonText(e.target.value.replace(/(\r\n|\n|\r)/gm, '').slice(0, maxDeclineReasonTextCharacters))}
                  />
                  {!!isMaxDeclineReasonTextCharacters &&
                    <span className="error-message">
                      {t('deal_assignment_card:decline_reasons:characters_length_message', { count: maxDeclineReasonTextCharacters })}
                    </span>
                  }
                </Stack>
              </div>
            </TransitionAppear>

            {/** REASON INFORMATION */}
            <TransitionAppear
              visible={
                reason === DeclineAssignmentReason.NOT_INTERESTED_COOP_WITH_BKBN
                && declineAssignmentReason === reason
              }
              onEnter={standardEnterEffect}
            >
              <div className={styles.children}>
                <span className={styles.warning}>
                  {t(`deal_assignment_card:decline_reason_info:${reason}`)}
                </span>
              </div>
            </TransitionAppear>

            {/** EXTRA REASON OPTIONS */}
            <TransitionAppear
              visible={reason === DeclineAssignmentReason.NOT_AVAILABLE && declineAssignmentReason === reason}
              onEnter={standardEnterEffect}
            >
              <div className={styles.children}>
                <Stack justifyContent="flex-start" alignItems="flex-start">
                  {Object.values(DeclineUnavailableOptions).map(option => (
                    <Fragment key={option}>

                      <label className="checkbox" htmlFor={`not-available-${option}`}>
                        <input
                          type="radio"
                          name="not-available"
                          id={`not-available-${option}`}
                          value={option}
                          checked={declineUnavailableReason === option}
                          onChange={() => setDeclineUnavailableReason(option)}
                        />
                        <span className="checkmark"></span>
                        <span className="label-after">{t(`deal_assignment_card:decline_reasons:${option}`)}</span>
                      </label>

                      <TransitionAppear
                        visible={
                          option === DeclineUnavailableOptions.NEW_UNAVAILABILITY
                          && declineUnavailableReason === DeclineUnavailableOptions.NEW_UNAVAILABILITY
                        }
                        onEnter={standardEnterEffect}
                      >
                        <Stack>
                          <Stack
                            flex="1 1 100%"
                            direction={{ sx: 'column', sm: 'row' }}
                            flexWrap="wrap"
                            gap={2}
                            paddingLeft={3.5}
                          >
                            <Box position="relative">
                              <MUIDatePicker
                                defaultValue={defaultFromDate}
                                value={fromDate}
                                onChange={(date) => setFromDate(date || defaultFromDate)}
                                timeSteps={{ minutes: 15 }}
                                timezone={userTimezone}
                                disablePast
                                format='DD/MM/YYYY HH:mm'
                                ampm={false}
                                isError={!!fromError}
                                errorText={t(`unavailability_drawer:errors.${fromError}`)}
                                slotProps={{
                                  popper: {
                                    placement: 'auto' // There is a weird behavior with the popper placement if it's not set
                                  }
                                }}
                              />
                            </Box>

                            <Box marginTop={2}>
                              <span>{t('common:to')}</span>
                            </Box>

                            <Box position="relative">
                              <MUIDatePicker
                                defaultValue={defaultToDate}
                                value={toDate}
                                minDateTime={fromDate.clone().add(15, 'minutes')}
                                onChange={(date) => setToDate(date || defaultToDate)}
                                timeSteps={{ minutes: 15 }}
                                timezone={userTimezone}
                                disablePast
                                format='DD/MM/YYYY HH:mm'
                                ampm={false}
                                isError={!!toError}
                                errorText={t(`unavailability_drawer:errors.${toError}`)}
                                slotProps={{
                                  popper: {
                                    placement: 'auto' // There is a weird behavior with the popper placement if it's not set
                                  }
                                }}
                              />
                            </Box>
                          </Stack>

                          {doesUnavailabilityClash &&
                            <Box sx={{
                              color: CORAL_600,
                              background: CORAL_100,
                              borderRadius: '6px',
                              padding: '1.5rem',
                              marginLeft: '2.5rem',
                              fontSize: '1.3rem',
                              lineHeight: 1.3,
                              marginTop: '2rem',
                            }}>
                              <Trans t={t} i18nKey="unavailability_drawer:clashing_assignment">
                                <a href={supportEmailHref()}>&nbsp;</a>
                              </Trans>
                            </Box>
                          }

                        </Stack>
                      </TransitionAppear>

                    </Fragment>
                  ))}
                </Stack>
              </div>
            </TransitionAppear>
          </div>
        )
      })}

      {/* ACTIONS & QUERY STATUS */}
      <Stack marginTop={2}>
        <span>{t('deal_assignment_card:decline_reasons:confirm')}</span>

        <QueryStatus query={declineAssignment} spaceTopRem={1} />

        {['error', 'idle'].includes(declineAssignment.status) &&
          <Stack direction="row" justifyContent="space-between" alignItems="center" marginTop={2}>
            <Button type="secondary nobackground" onClick={onClose}>
              {t('common:Cancel')}
            </Button>
            <Button onClick={handleDeclineAssignment} disabled={isConfirmDisabled}>
              {t('deal_assignment_card:decline_assignment')}
            </Button>
          </Stack>
        }
      </Stack>

    </Modal>
  )
}
