import { Dispatch, FC, SetStateAction, useMemo } from 'react'
import { Stack, useMediaQuery } from '@mui/material'

import { AssignmentStage } from 'constants/assignment'
import { ClientGalleryDocuments } from '../ClientGalleryDocuments'
import { ClientGalleryEmptyContent } from '../ClientGalleryEmptyContent'
import { ClientGalleryPhoto } from '../ClientGalleryPhoto'
import { ClientGalleryVirtualVisit } from '../ClientGalleryVirtualVisit'
import { FeatureFlag } from 'utils/featureFlags'
import { MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { Tab } from '../_main/ClientGallery.controller'
import { useFlag } from '@unleash/proxy-client-react'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useGalleryProduct } from 'components/pages/Gallery/_main/contexts/GalleryProduct.context'
import { useGalleryVirtualVisit } from 'components/pages/Gallery/_main/contexts/GalleryVirtualVisit.context'

/**
 * @interface Props
 */
interface Props {
  /** Set current active tab */
  setActiveTab: Dispatch<SetStateAction<Tab>>
}
/**
 * Client gallery content.
 *
 * @example <ClientGalleryContent />
 */
export const ClientGalleryContent: FC<Props> = ({ setActiveTab }) => {
  const allowAuthoritiesDocs = useFlag(FeatureFlag.ALLOW_AUTHORITIES_DOCUMENTS)

  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const { isVirtualVisit, isDocumentProduct } = useGalleryProduct()
  const { virtualVisitID } = useGalleryVirtualVisit()
  const { assignmentStage } = useGalleryAssignment()

  const isVisualSendToClient = useMemo(() => assignmentStage === AssignmentStage.VISUALS_SENT_TO_CLIENT, [assignmentStage])
  const isDocSendToClient = useMemo(() => assignmentStage === AssignmentStage.DOCUMENTS_SENT_TO_CLIENT, [assignmentStage])

  return (
    <Stack width="100%" paddingTop={isMobileView ? '10rem' : '0'}>

      {/** ASSIGNMENT NOT DELIVERED TO CLIENT */}
      {!isVisualSendToClient && (
        <ClientGalleryEmptyContent onButtonClick={() => setActiveTab(Tab.ORDER_INFO)} />
      )}

      {/** ASSIGNMENT DELIVERED TO CLIENT. Photo, staging, video... etc */}
      {isVisualSendToClient && !isVirtualVisit && !isDocumentProduct && <ClientGalleryPhoto />}

      {/** ASSIGNMENT DELIVERED TO CLIENT. Matterport - Virtual visit */}
      {isVisualSendToClient && isVirtualVisit && !!virtualVisitID && (
        <ClientGalleryVirtualVisit />
      )}

      {/** ASSIGNMENT DELIVERED TO CLIENT. Documents */}
      {allowAuthoritiesDocs && isDocSendToClient && isDocumentProduct && <ClientGalleryDocuments />}

    </Stack>
  )
}
