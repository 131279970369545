import * as Sentry from '@sentry/react'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useProductAndInstructionLists, usePurchaseFlowConfig, usePurchaseFlowOrderMeta, usePurchaseFlowProducts, useTargetOrderUser } from 'components/pages/PurchaseFlow/_main/contexts'
import { useBookCreative } from 'components/pages/PurchaseFlow/common'

import { APIRequestErrorType } from 'constants/API'
import { EditingCategory, ProductKind, ProductSegment } from 'constants/product'
import constate from 'constate'
import { usePlaceOrder } from 'dataQueries/purchase.query'
import { Coordinates, KeyPickupDTO, MeetingOnSiteDTO, OrganizationThirdPartyDTO, PlaceOrderBody, ProductListItemDTO, RealEstatePropertyDTO } from 'models/purchaseFlow'
import { StagingConfiguration, StagingRequestDTO } from 'models/virtualStaging'
import { Moment } from 'moment'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { AnalyticsEvent, logAnalyticsEvent, trackIntercomEvent } from 'utils/analytics'
import { useAuth0 } from 'utils/auth'
import { getCityFromGooglePlace } from 'utils/location'
import { bigFromDecimal } from 'utils/price'
import { getAPIRoomItemFromStagingRoom } from 'utils/serialization/getAPIRoomItemFromStagingRoom'
import { isEditingCategory } from 'utils/validators'
import { getPropertyAddressFromGooglePlace } from 'utils/location/googlePlaceUtils'
import { useFloorPlanConfig } from '../../../FloorPlanConfig/_main/FloorPlanConfigStep.context'
import { usePurchaseFlowPaymentStatus } from './PurchaseFlowPaymentStatus.context'

export const [PurchaseFlowPlaceOrderProvider, usePurchaseFlowPlaceOrder] = constate(() => {
  const { t } = useTranslation(['order', 'category', 'product'])
  const { roles } = useAuth0()
  const { adminSelectedUserEmail } = useTargetOrderUser()
  const {
    getProductQuantityList,
    getProductFullList,
    getInstructionFullList,
    getInstructionQuantityList,
  } = useProductAndInstructionLists()

  const { selectedCreative } = useBookCreative()

  const { spawnErrorToast, spawnSuccessToast } = useSnackbar()

  const {
    sessionId,
    selectedCategory,
    organizationInstructions,
    selectedCountryCode,
    selectedAssignmentPlace,
    catalogueCurrency,
    catalogueDiscount,
    catalogueVat,
    catalogueTimezone,
    externalReportingInstruction,
    enableSkipAutomation,
  } = usePurchaseFlowConfig()

  const { getFloorPlanRequestData } = useFloorPlanConfig()

  const {
    reference,
    VATNumber,
    billingAddress,
    shootingComments,
    meetingInformation,
    propertyOwner,
    selectedMeetingType,
    selectedExtraServices,
    isStripePaymentMethod,
    externalReportingField,
    selectedKeyPickupPlace,
    keyPickupTravelCostProduct,
  } = usePurchaseFlowOrderMeta()

  const { selectedSegment, hasFloorPlanProduct } = usePurchaseFlowProducts()
  const {
    isStripeProcessing,
    isReceiptEmailValid,
    selectedSavedPaymentMethod,
    stripeInputElementsAreValid,
    setIsStripeConfirmed,
    setStripeError,
    setIsStripeProcessing
  } = usePurchaseFlowPaymentStatus()

  const { getAllRooms, allRoomsCompleted } = useRoomAPI()

  const placeOrderMutation = usePlaceOrder()

  /** Serializes staging configuration information into payload */
  const getStagingConfig = useCallback((): StagingRequestDTO | undefined => {
    // state guard
    if (selectedCategory !== EditingCategory.STAGING || !allRoomsCompleted) return undefined

    const stagedRooms = getAllRooms()

    // Reduce room objects to Records of config items indexed by image id
    const roomDetails = stagedRooms.reduce((stagingMap, room) => {

      const roomConfig = getAPIRoomItemFromStagingRoom(room)

      const imageStyleMap: StagingConfiguration = room.images.reduce((imageMap, { id }) => ({
        ...imageMap,
        [id]: roomConfig
      }), {})

      return {
        ...stagingMap,
        ...imageStyleMap,
      }
    }, {})

    return {
      details: roomDetails
    }

  }, [allRoomsCompleted, getAllRooms, selectedCategory])

  const orderIsBeingPlaced = useMemo(() => placeOrderMutation.isPending, [placeOrderMutation.isPending])
  const orderHasBeenPlaced = useMemo(() => placeOrderMutation.isSuccess, [placeOrderMutation.isSuccess])
  const orderPlacementHasError = useMemo(() => placeOrderMutation.isError, [placeOrderMutation.isError])
  const orderPlacementForbiddenErrorMessage = useMemo(() => {
    if (!placeOrderMutation.isError) return undefined

    if (placeOrderMutation.error.code === APIRequestErrorType.FORBIDDEN_ERROR) return (placeOrderMutation.error.response?.data as any)?.message ?? undefined

    return undefined
  }, [placeOrderMutation])

  const vatNumber = useMemo(() => bigFromDecimal(catalogueVat).toNumber(), [catalogueVat])
  const discountNumber = useMemo(() => bigFromDecimal(catalogueDiscount).toNumber(), [catalogueDiscount])

  const isOrderOrStripeInProgress = useMemo(() => isStripePaymentMethod ? (orderIsBeingPlaced || isStripeProcessing) : orderIsBeingPlaced, [isStripeProcessing, isStripePaymentMethod, orderIsBeingPlaced])

  const isPlaceOrderButtonDisabled = useMemo(() => {
    // Submit is already processing
    if (isOrderOrStripeInProgress) return true

    // Case of stripe not being used
    if (!isStripePaymentMethod) return false

    // Stripe is being used
    // if (selectedSavedPaymentMethod === null) return true
    if (!isReceiptEmailValid) return true
    if (selectedSavedPaymentMethod === null && !stripeInputElementsAreValid) return true

    return false
  }, [isOrderOrStripeInProgress, isReceiptEmailValid, isStripePaymentMethod, selectedSavedPaymentMethod, stripeInputElementsAreValid])

  const formatOrderDateToString = useCallback((startDate: Moment) => {
    if (!catalogueTimezone) return ''
    const dateTime = startDate.utc(true).tz(catalogueTimezone, true)

    return dateTime.toISOString()
  }, [catalogueTimezone])

  const getExternalReporting = useCallback(() => {
    if (!externalReportingInstruction) return undefined

    return externalReportingField
  }, [externalReportingField, externalReportingInstruction])

  const getMeetingOnSite = useCallback((): MeetingOnSiteDTO | undefined => {
    if (selectedMeetingType !== ProductKind.MEETING_ON_SITE) return undefined

    const { date, name, phone, comments, email } = meetingInformation[ProductKind.MEETING_ON_SITE]

    if (!date || !catalogueTimezone || !name) return undefined

    return {
      name,
      phone,
      email,
      comment: comments,
      date: formatOrderDateToString(date),
      timezone: catalogueTimezone,
    }
  }, [catalogueTimezone, formatOrderDateToString, meetingInformation, selectedMeetingType])

  const getOrganizationThirdParty = useCallback((): OrganizationThirdPartyDTO | undefined => {
    if (selectedMeetingType !== ProductKind.ORGANIZATION_THIRD_PARTY) return undefined

    const { name, phone, comments, email } = meetingInformation[ProductKind.ORGANIZATION_THIRD_PARTY]

    if (!catalogueTimezone || !name) return undefined

    return {
      name,
      phone,
      email,
      comment: comments,
      timezone: catalogueTimezone,
    }
  }, [catalogueTimezone, meetingInformation, selectedMeetingType])

  const getKeyPickup = useCallback((): KeyPickupDTO | undefined => {
    if (selectedMeetingType !== ProductKind.KEYS_PICKUP) return undefined

    const { name, phone, comments, email, date } = meetingInformation[ProductKind.KEYS_PICKUP]

    if (!date || !selectedKeyPickupPlace || !catalogueTimezone) return undefined

    return {
      name,
      phone,
      email,
      comment: comments,
      date: formatOrderDateToString(date),
      address: selectedKeyPickupPlace.address,
      timezone: catalogueTimezone,
      coordinates: selectedKeyPickupPlace.coordinate,
    }
  }, [catalogueTimezone, formatOrderDateToString, meetingInformation, selectedKeyPickupPlace, selectedMeetingType])

  const handlePlaceOrder = useCallback(() => {
    if (orderIsBeingPlaced || orderHasBeenPlaced) return
    if (!selectedCategory || !sessionId) return
    if (!selectedAssignmentPlace && !isEditingCategory(selectedCategory)) return
    if (!organizationInstructions) return

    const stagingConfiguration = getStagingConfig()
    if (selectedCategory === EditingCategory.STAGING && !stagingConfiguration) return
    const isFavouriteCTSelected = selectedExtraServices.has(ProductKind.FAVOURITE_CREATIVE)
    if (isFavouriteCTSelected && !selectedCreative) return

    const isKeyPickupSelected = selectedMeetingType === ProductKind.KEYS_PICKUP

    if (isKeyPickupSelected && !keyPickupTravelCostProduct) return

    const selectedFavouriteCreativeId = isFavouriteCTSelected ? selectedCreative?.id : undefined

    const quantityProductList = [
      ...getProductQuantityList(),
      ...getInstructionQuantityList(),
    ]

    const fullProductList: ProductListItemDTO[] = [
      ...getProductFullList(),
      ...getInstructionFullList(),
    ]

    // Append Favourite CT travel cost product if Favourite CT is selected
    if (isFavouriteCTSelected && selectedCreative) {

      if (selectedCreative.travelCost.productQuantity > 0) {
        const productItem = {
          id: selectedCreative.travelCost.productId,
          count: selectedCreative.travelCost.productQuantity,
          isOption: false,
        }

        quantityProductList.push(productItem)
        fullProductList.push({
          ...productItem,
          name: t(`product:p_${productItem.id}`),
          pricePerItem: selectedCreative.travelCost.productUnitPrice,
        })
      }
    }

    // Append Key pickup travel cost if key pickup is selected and quantity it not 0
    if (isKeyPickupSelected && keyPickupTravelCostProduct) {

      const productItem = {
        id: keyPickupTravelCostProduct.productId,
        count: keyPickupTravelCostProduct.productQuantity || 0,
        isOption: false,
      }

      quantityProductList.push(productItem)
      fullProductList.push({
        ...productItem,
        name: t(`product:p_${productItem.id}`),
        pricePerItem: keyPickupTravelCostProduct.productUnitPrice,
      })
    }

    const floorPlanConfigData = hasFloorPlanProduct ? getFloorPlanRequestData() : undefined


    let placeObject: {
      countryCode?: string,
      address?: string,
      coordinates?: Coordinates,
      realEstateProperty?: RealEstatePropertyDTO
    } = {}

    if (selectedCountryCode) {
      placeObject = {
        ...placeObject,
        countryCode: selectedCountryCode,
      }
    }

    if (selectedAssignmentPlace) {
      placeObject = {
        ...placeObject,
        countryCode: selectedCountryCode,
        address: selectedAssignmentPlace?.formatted_address,
        coordinates: selectedAssignmentPlace.geometry.location,
      }
    }

    // Handle property details - TODO later we should extend this functionality for other products flow to use property first functionality
    if (floorPlanConfigData?.propertyDetails) {
      const propertyDetails = floorPlanConfigData.propertyDetails
      const address = getPropertyAddressFromGooglePlace(propertyDetails.propertyAddress ?? null)

      if (propertyDetails.propertyType || address) {
        placeObject = {
          ...placeObject,
          realEstateProperty: {
            propertyType: propertyDetails.propertyType,
            address: address ?? undefined,
          }
        }
      }
    }

    const orderComments = fullProductList.reduce((acc, product) => {
      if (product.comment) {
        return `${acc}\n\n${t(`product:p_${product.id}`)} [#${product.id}]: ${product.comment}`
      }

      return acc
    }, shootingComments)

    const body: PlaceOrderBody = {
      sessionId,
      data: {
        ...placeObject,
        category: selectedCategory,
        segment: selectedSegment || ProductSegment.all,
        products: quantityProductList,
        // Instructions
        comments: orderComments,
        meetingOnSite: getMeetingOnSite(),
        organizationThirdParty: getOrganizationThirdParty(),
        keyPickup: getKeyPickup(),
        // Floor Plan config
        floorPlanRequest: floorPlanConfigData ? Object.assign({}, floorPlanConfigData, { propertyDetails: undefined }) : undefined, // We don't want to send unnecessary data back to BE
        // Billing
        billingAddress,
        vatNumber: VATNumber,
        externalReporting: getExternalReporting(),
        // Staging
        stagingRequest: stagingConfiguration,
        // Book CT
        selectedFavouriteCreativeId,
        // Other
        reference,
        propertyOwner,
        skipAutomation: enableSkipAutomation,
        city: getCityFromGooglePlace(selectedAssignmentPlace),
        scheduledByCT: selectedMeetingType === ProductKind.ORGANIZATION_THIRD_PARTY,
      },
    }

    if (roles.isAdmin && adminSelectedUserEmail) body.data.email = adminSelectedUserEmail

    const extras = {
      currency: catalogueCurrency,
      vat: vatNumber,
      discount: discountNumber,
      roles,
    }

    logAnalyticsEvent(AnalyticsEvent.PURCHASE_FLOW_PURCHASE, {})

    placeOrderMutation.mutate(
      body,
      {
        onSuccess: () => {
          trackIntercomEvent(AnalyticsEvent.ORDER_PURCHASED)
          spawnSuccessToast(t('step_validation.order_placed.title'))
        },
        onError: (error) => {
          spawnErrorToast(t('step_validation.order_error_text'), { error: error.request })

          const message = 'placeOrder - request state is ERROR'
          return Sentry.captureMessage(message, scope => {
            scope.clearBreadcrumbs()
            scope.setFingerprint([message])
            scope.setExtra('actionBody', body)
            scope.setExtra('fullProductList', fullProductList)
            scope.setExtra('extras', extras)
            return scope
          })
        }
      }
    )
  }, [orderIsBeingPlaced, orderHasBeenPlaced, selectedCategory, sessionId, selectedAssignmentPlace, organizationInstructions, getStagingConfig, selectedExtraServices, selectedCreative, selectedMeetingType, keyPickupTravelCostProduct, getProductQuantityList, getInstructionQuantityList, getProductFullList, getInstructionFullList, selectedCountryCode, shootingComments, hasFloorPlanProduct, getFloorPlanRequestData, selectedSegment, getMeetingOnSite, getOrganizationThirdParty, getKeyPickup, billingAddress, VATNumber, getExternalReporting, reference, propertyOwner, enableSkipAutomation, roles, adminSelectedUserEmail, catalogueCurrency, vatNumber, discountNumber, placeOrderMutation, t, spawnSuccessToast, spawnErrorToast])

  const onPlaceOrder = useCallback(() => {
    setIsStripeConfirmed(false)
    setStripeError(null)

    if (isStripePaymentMethod) {
      setIsStripeProcessing(true)
    }

    if (!orderHasBeenPlaced) {
      handlePlaceOrder()
    }
  }, [handlePlaceOrder, isStripePaymentMethod, orderHasBeenPlaced, setIsStripeConfirmed, setIsStripeProcessing, setStripeError])

  return {
    orderIsBeingPlaced,
    orderHasBeenPlaced,
    orderPlacementHasError,
    orderPlacementForbiddenErrorMessage,
    isOrderOrStripeInProgress,
    isPlaceOrderButtonDisabled,
    placeOrderMutation,
    handlePlaceOrder,
    onPlaceOrder,
  }
})
