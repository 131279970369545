import { Stack } from '@mui/material'
import { PageLayout } from 'components/common/Page/PageLayout'
import { WarningAmber } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'

export const NoRoleGallery: React.FC = () => {
  const { t } = useTranslation('gallery')

  return (
    <PageLayout
      header={undefined}
      layoutErrorContent={undefined}
    >
      <Stack flexDirection="row" alignItems="center" justifyContent="center" paddingTop="2rem">
        <WarningAmber /> {t('no_gallery_for_role')}
      </Stack>
    </PageLayout>
  )
}
