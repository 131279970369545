import { AuthoritiesDocumentsProvider, CartContextProvider, ProductAndInstructionListsContextProvider, PurchaseFlowRenovationProductContextProvider, TargetOrderUserProvider, UploadedPurchaseFlowVisualsContextProvider } from './contexts'
import { PurchaseFlowPaymentStatusContextProvider, PurchaseFlowPlaceOrderProvider } from '../steps/Validation/_main/contexts'

import { BookCreativeContextProvider } from '../common/InstructionListing/_main/contexts'
import { DynamicFlowProvider } from 'components/common/DynamicFlow'
import { FloorPlanConfigProvider } from '../steps/FloorPlanConfig/_main/FloorPlanConfigStep.context'
import { FloorPlanLogoProvider } from '../steps/FloorPlanConfig/_main/FloorPlanLogo.context'
import { PropertyInformationStepContextProvider } from '../steps/PropertyInformationStep/PropertyInformationStep.context'
import { PurchaseFlowConfigProvider } from './contexts/PurchaseFlowConfig.context'
import { PurchaseFlowController } from './PurchaseFlow.controller'
import { PurchaseFlowOrderMetaProvider } from './contexts/PurchaseFlowOrderMeta.context'
import { PurchaseFlowProductsProvider } from './contexts/PurchaseFlowProducts.context'
import { PurchaseFlowStagingStack } from './contexts/PurchaseFlowStagingStack'
import { PurchaseFlowUtilityProvider } from './contexts/PurchaseFlowUtilities.context'
import React from 'react'
import { SavedStripePaymentMethodsContextProvider } from 'components/contexts/SavedStripePaymentMethodsContext'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { redesignMUITheme } from 'constants/styling/redesignTheme'
import { useAuth0 } from 'utils/auth'

/**
 * Page representing the overall purchase flow content.
 *
 * @example <PurchaseFlow />
*/
export const PurchaseFlow: React.FC = () => {
  const { roles } = useAuth0()

  return (
    <ThemeProvider theme={redesignMUITheme}>

      <TargetOrderUserProvider>
        <PurchaseFlowConfigProvider>
          <PurchaseFlowProductsProvider>

            <BookCreativeContextProvider>
              <PurchaseFlowOrderMetaProvider>
                <AuthoritiesDocumentsProvider>

                  <CartContextProvider>

                    <ProductAndInstructionListsContextProvider>

                      <UploadedPurchaseFlowVisualsContextProvider>
                        <PurchaseFlowRenovationProductContextProvider>
                          <PurchaseFlowStagingStack>

                            <SavedStripePaymentMethodsContextProvider autoFetch={!roles.isAdmin}>
                              <PurchaseFlowPaymentStatusContextProvider>

                                <DynamicFlowProvider>
                                  <FloorPlanConfigProvider>
                                    <FloorPlanLogoProvider>
                                      <PurchaseFlowPlaceOrderProvider>
                                        <PropertyInformationStepContextProvider>

                                          <PurchaseFlowUtilityProvider>

                                            <PurchaseFlowController />

                                          </PurchaseFlowUtilityProvider>

                                        </PropertyInformationStepContextProvider>
                                      </PurchaseFlowPlaceOrderProvider>

                                    </FloorPlanLogoProvider>
                                  </FloorPlanConfigProvider>

                                </DynamicFlowProvider>

                              </PurchaseFlowPaymentStatusContextProvider>
                            </SavedStripePaymentMethodsContextProvider>

                          </PurchaseFlowStagingStack>
                        </PurchaseFlowRenovationProductContextProvider>
                      </UploadedPurchaseFlowVisualsContextProvider>

                    </ProductAndInstructionListsContextProvider>

                  </CartContextProvider>

                </AuthoritiesDocumentsProvider>
              </PurchaseFlowOrderMetaProvider>
            </BookCreativeContextProvider>

          </PurchaseFlowProductsProvider>
        </PurchaseFlowConfigProvider>
      </TargetOrderUserProvider>

    </ThemeProvider>
  )
}
