import { BEIGE_300, BEIGE_700, BLUE_600, MOBILE_VIEW_QUERY } from 'constants/styling/theme'
import { Box, Stack, useMediaQuery } from '@mui/material'
import { DynamicFlowStepper, DynamicFlowView, useDynamicFlowController } from 'components/common/DynamicFlow'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useLayoutEffect } from 'react'

import { APIRequestState } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import { RootStore } from 'models/redux'
import { SignupCreateAccountStep } from './SignupCreateAccountStep'
import { SignupCreateWorkspaceStep } from './SignupCreateWorkspaceStep'
import { useTranslation } from 'react-i18next'
import { userMeAlternative } from 'redux/Individual/User/UserMeAlternative'

/** Enum of all registration steps */
export enum RegistrationStepKey {
  CREATE_ACCOUNT = 'CREATE_ACCOUNT',
  CREATE_WORKSPACE = 'CREATE_WORKSPACE',
}

export const SignupRegistrationController: React.FC = (props: any) => {
  const { t } = useTranslation(['signup', 'country', 'language', 'common'])
  const flowCtrl = useDynamicFlowController()
  const dispatch = useDispatch()
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)

  const userMeAlternativeRequest = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.USER_ME_ALTERNATIVE])

  useEffect(() => {

    flowCtrl.createEntryNode(
      RegistrationStepKey.CREATE_ACCOUNT,
      {
        component: <SignupCreateAccountStep />,
        hasFooter: false,
        type: 'main',
      }
    )

    flowCtrl.insertAfter(
      RegistrationStepKey.CREATE_ACCOUNT,
      RegistrationStepKey.CREATE_WORKSPACE,
      {
        component: <SignupCreateWorkspaceStep />,
        hasFooter: false,
        type: 'main',
      }
    )

    // No dependencies so it runs only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.setTimeout(() => {
      dispatch(userMeAlternative())
    }, 2000)
  }, [dispatch])

  useLayoutEffect(() => {
    if (userMeAlternativeRequest.state === APIRequestState.OK) {
      window.location.reload()
    } else if (userMeAlternativeRequest.state === APIRequestState.ERROR) {
      window.setTimeout(() => {
        dispatch(userMeAlternative())
      }, 2000)
    }
  }, [dispatch, userMeAlternativeRequest])

  return (
    <Stack
      height="100vh"
      justifyContent="flex-start"
      bgcolor={BEIGE_300}
      overflow="hidden"
      paddingTop="6.4rem"
      position="relative"
    >

      {!isMobileView &&
        <Stack
          padding="2rem 0"
          gap={2}
          justifyContent="center"
          alignItems="center"
          height="6.8rem"
          position="fixed"
          width="100%"
          sx={{ background: BEIGE_300 }}
        >
          <Box minWidth="50rem">
            <DynamicFlowStepper stepNameTranslatorFnc={(key) => t(`steps.${key}`)} />
          </Box>
        </Stack>
      }

      {isMobileView &&
        <Stack gap=".4rem" padding="2.4rem 1.6rem" flexDirection="row" position="fixed" top="6.4rem" width="100%" boxSizing="border-box" sx={{ background: BEIGE_300 }}>
          {Object.keys(RegistrationStepKey).map((key, index) => {
            const nodeList = flowCtrl.getOrderedNodeList()
            const currentStep = flowCtrl.currentNode
            const currentStepIndex = currentStep ? nodeList.findIndex((node) => node.key === currentStep.key) : 0

            return <Box key={key} width="100%" height='.4rem' borderRadius=".4rem" sx={{ background: index <= currentStepIndex ? BLUE_600 : BEIGE_700 }} />
          })}
        </Stack>
      }

      <Box
        flex="1 1 100%"
        width="100%"
        height="100%"
        overflow="auto"
      >
        <Stack
          width="100%"
          height="100%"
          alignItems="center"
          padding={isMobileView ? '0' : '8rem 4rem'}
          boxSizing="border-box"
        >
          <DynamicFlowView />
        </Stack>
      </Box>

    </Stack>
  )
}
