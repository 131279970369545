import { DynamicFlowProvider } from 'components/common/DynamicFlow'
import React from 'react'
import { SignupRegistrationController } from './SignupRegistration.controller'
import { SignupRegistrationProvider } from './SignupRegistration.context'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import { redesignMUITheme } from 'constants/styling/redesignTheme'

/**
 * Page representing the overall signup registration content.
 *
 * @example <SignupRegistration />
*/
export const SignupRegistration: React.FC = () => {

  return (
    <ThemeProvider theme={redesignMUITheme}>
      <DynamicFlowProvider>
        <SignupRegistrationProvider>
          <SignupRegistrationController />
        </SignupRegistrationProvider>
      </DynamicFlowProvider>
    </ThemeProvider>
  )
}
