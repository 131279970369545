import './TopBar.sass'

import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Transition, TransitionGroup } from 'react-transition-group'
import { changeLanguage, changeLocale } from 'redux/Individual/User/ChangeLocale'
import { coerceLanguage, getContentLibraryLink } from 'utils/localization'

import Button from '../Button/Button'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import { ContactUsPopup } from './HelpCenter/ContactUsPopup.component'
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined'
import DataObjectOutlinedIcon from '@mui/icons-material/DataObjectOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import Dropdown from '../Dropdown/Dropdown'
import { HelpCenter } from './HelpCenter/HelpCenter.component'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { Language } from 'translations/Language'
import { LanguagePopup } from 'components/common/TopBar/LanguagePopup/LanguagePopup.component'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined'
import Logo from '../Logo/Logo'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import { MUIBadge } from '../MUIBadge'
import { MUIDivider } from 'components/common/MUIDivider/MUIDivider.component'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined'
import { Path } from 'constants/router'
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { Stack } from '@mui/material'
import { getHelpCenterLink } from 'utils/localization/helpCenter'
import { gsap } from 'gsap'
import i18n from 'translations/i18n'
import { logAnalyticsEvent } from 'utils/analytics'
import { useAppMeta } from 'components/higher-order/App/appMeta.context'
import { useAuth0 } from 'utils/auth'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useUserData } from 'components/contexts/UserDataContext'

/**
 * @interface Props Input properties
 */
export interface Props {
  /** The additional classes to append */
  className?: string
}

/**
 * @component A component usually display at the top of the page containing a logo, login/sign-up buttons and profile info as well as menu links for logged in users. Contains also mobile version of a menu.
 * @example <TopBar className="class" />
 */
const TopBar: React.FC<Props> = ({
  className = '',
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['topbar', 'authentication', 'gallery', 'category', 'product', 'dashboard_client'])
  const { isAuthenticated, loading, logout, user, roles } = useAuth0()
  const {
    activeSubscribedUserWorkspaces,
    hasUserActiveSubscription,
    currentUserWorkspace,
    clientData
  } = useUserData()
  const { isUserSet } = useAppMeta()
  const location = useLocation()
  const navigate = useNavigate()
  const [isLanguageOpen, setIsLanguageOpen] = useState(false)
  const [isContactUsOpen, setIsContactUsOpen] = useState(false)
  const invitationWorkspaceName = useMemo(() => activeSubscribedUserWorkspaces.find(item => item.hasOwnProperty('invitationId'))?.name, [activeSubscribedUserWorkspaces])

  /** logout action handle */
  const handleLogout = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault()
    logout()
    closeMenuHandler(e)
  }

  // Open / Close logic
  /** inner menu element reference */
  const menuRef = useRef<HTMLDivElement>(null)
  const [showMenu, setShowMenu] = useState(false)

  /** action called upon mobile menu closing */
  const closeMenuAction = useCallback((e: any) => {
    if (menuRef.current?.contains(e.target)) return

    setShowMenu(false)
    document.removeEventListener('click', closeMenuAction)
  }, [])

  /** action called upon mobile menu opening */
  const openMenuAction = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault()
    if (showMenu) return

    setShowMenu(true)
    window.setTimeout(() => {
      document.addEventListener('click', closeMenuAction)
    }, 0)
  }, [showMenu, closeMenuAction])

  /** timeline for close animations */
  const closeMenuHandler = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    document.removeEventListener('click', closeMenuAction)
    setShowMenu(false)
  }, [closeMenuAction])

  // Unsubscribe event listener
  useEffect(() => {
    return () => {
      document.removeEventListener('click', closeMenuAction)
    }
  }, [closeMenuAction])

  /** handle menu when window resize */
  useEffect(() => {
    const resizeHandler = (e: any) => {
      const screenWidth = window.innerWidth
      if (screenWidth > 800) {
        closeMenuHandler(e)
      }
    }
    window.addEventListener('resize', resizeHandler)
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [closeMenuAction, closeMenuHandler])

  // Animations
  /** timeline for open animations */
  const timeline_open = gsap.timeline({ paused: true })
  /** timeline for close animations */
  const timeline_close = gsap.timeline({ paused: true })

  /** animation timeline for mobile menu open effect - center version */
  const centerOpenEffect = (node: HTMLElement, appears: boolean) => {
    timeline_close.fromTo('.mobile-main-menu', { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.2 })
    timeline_close.fromTo('.menu-list', { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.6 }, 0.35)
    timeline_close.play()
  }

  /** animation timeline for mobile menu open effect - center version */
  const centerCloseEffect = (node: HTMLElement) => {
    timeline_open.to(node, { autoAlpha: 0, duration: 0 }, 0.1)
    timeline_open.play()
  }

  if (!isAuthenticated && (location.pathname === '' || location.pathname === '/')) className += ' index-notlogged'

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedLanguage = useMemo(() => coerceLanguage(i18n.language), [i18n.language])

  /** Show workspace name if client has only one active non-personal workspace and no multi-space invitation */
  const isShowWorkspaceName = useMemo(
    () => roles.isClient
      && (hasUserActiveSubscription && !!currentUserWorkspace && activeSubscribedUserWorkspaces.length === 1)
      || !!invitationWorkspaceName,
    [
      invitationWorkspaceName,
      activeSubscribedUserWorkspaces.length,
      currentUserWorkspace,
      hasUserActiveSubscription,
      roles.isClient
    ]
  )

  const languageDropdown = (
    <Dropdown className="language-dropdown" attachment="right-attached" button={(isOpen, action) => (
      <Button type="secondary noborder" height='thin' className="language-trigger" onClick={action}>
        {selectedLanguage}
      </Button>
    )}>
      <Fragment>
        {Object.values(Language)?.map(lang => {
          const selected = lang.toUpperCase() === selectedLanguage
          return (
            <button
              key={lang}
              className={`language-button ${selected ? 'selected' : ''}`}
              onClick={() => {
                dispatch(changeLanguage(lang))
                if (!isAuthenticated) return
                dispatch(changeLocale(lang))
              }}
            >
              {t(`language:${lang.toUpperCase()}`, lang)}
            </button>
          )
        })}
      </Fragment>
    </Dropdown>
  )

  return (
    <Fragment>
      <header className={`topbar ${className} ${!(isAuthenticated && user) && 'login-topbar'}`.replace(/\s+/igm, ' ').trim()}>
        <Link
          to={Path.INDEX}
          className="logo-link"
          onClick={(e) => {
            logAnalyticsEvent('Click Backbone Logo', {
              userEmail: clientData?.email,
              organizationId: clientData?.organizationId,
            })
          }}
        >
          <Logo />
        </Link>
        {isShowWorkspaceName &&
          <Link
            to={Path.WORKSPACES_PROFILE.replace(':id', currentUserWorkspace?.id ?? '')}
            onClick={(e) => {
              logAnalyticsEvent('Click Backbone Plan Name', {
                userEmail: clientData?.email,
                organizationId: clientData?.organizationId,
                planId: currentUserWorkspace?.id
              })
              closeMenuHandler(e)
            }}
          >
            <div className='workspace'>
              <MUIDivider margin={12} orientation="vertical" />
              <div className='name'>{invitationWorkspaceName || currentUserWorkspace?.name}</div>
              {!!invitationWorkspaceName &&
                <Stack marginLeft={2}>
                  <MUIBadge color="coral" size="sm" label={t('pending')} />
                </Stack>
              }
            </div>
          </Link>
        }
        <div className="push-right onlymobile">
          {!(isAuthenticated && user) ?
            languageDropdown
            :
            <Button
              className="burger-menu-button"
              type="secondary"
              onClick={(e) => {
                logAnalyticsEvent('Click main menu', {
                  userEmail: clientData?.email,
                  organizationId: clientData?.organizationId,
                })
                showMenu ? closeMenuHandler(e) : openMenuAction(e)
              }}
            >
              {user && <img src={user.picture} alt={t('profile')} />}
              {showMenu ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
            </Button>
          }
        </div>
        {!loading &&
          <Fragment>
            {isAuthenticated && user ?
              <div className="profile-block push-right">

                {!roles.isAdmin && isUserSet && <HelpCenter isContactUsOpen={isContactUsOpen} setIsContactUsOpen={setIsContactUsOpen} />}

                {(roles.isClient || roles.isAdmin) &&
                  <Button
                    type="primary"
                    className='order-visuals-button'
                    onClick={() => {
                      logAnalyticsEvent('Click Order new visuals', {
                        userEmail: clientData?.email,
                        organizationId: clientData?.organizationId,
                      })
                      navigate(Path.PURCHASE_FLOW)
                    }}
                  >
                    {t(roles.isClient ? 'client_order_button' : 'admin_order_button')}
                  </Button>
                }

                {<LanguagePopup isLanguageOpen={isLanguageOpen} closeCallback={() => setIsLanguageOpen(!isLanguageOpen)} />}

                {<ContactUsPopup isContactUsOpen={isContactUsOpen} setIsContactUsOpen={setIsContactUsOpen} />}

                <Dropdown
                  innerMenuClassName='menu-wrap'
                  attachment="right-attached"
                  button={(isOpen, action) => (
                    <Button
                      type="secondary"
                      className='burger-menu-button'
                      onClick={(e) => {
                        logAnalyticsEvent('Click main menu', {
                          userEmail: clientData?.email,
                          organizationId: clientData?.organizationId,
                        })
                        action(e)
                      }}
                    >
                      <img src={user.picture} alt={t('profile')} />
                      {isOpen ? <CloseRoundedIcon /> : <MenuRoundedIcon />}
                    </Button>
                  )}>
                  <div className='main-menu'>
                    <div className='section'>
                      {isUserSet &&
                        <Link
                          to={Path.INDEX}
                          onClick={() => {
                            logAnalyticsEvent('Click main menu - Dashboard', {
                              userEmail: clientData?.email,
                              organizationId: clientData?.organizationId,
                            })
                          }}
                        >
                          <HomeOutlinedIcon className='icon' />
                          {t('dashboard')}
                        </Link>
                      }
                      <Button
                        type="secondary nobackground noborder"
                        className="language-settings-button"
                        textAndIcon
                        onClick={() => {
                          logAnalyticsEvent('Click main menu - Language', {
                            userEmail: clientData?.email,
                            organizationId: clientData?.organizationId,
                          })
                          setIsLanguageOpen(!isLanguageOpen)
                        }}
                      >
                        <ChatOutlinedIcon className='icon' />
                        {t('language_settings')}
                      </Button>
                      {roles.isClient && hasUserActiveSubscription && !!activeSubscribedUserWorkspaces &&
                        <Link
                          to={Path.WORKSPACES_PROFILE.replace(':id', activeSubscribedUserWorkspaces[0].id)}
                          onClick={() => {
                            logAnalyticsEvent('Click main menu - My Plan', {
                              userEmail: clientData?.email,
                              organizationId: clientData?.organizationId,
                              planId: activeSubscribedUserWorkspaces[0].id
                            })
                          }}
                        >
                          <CreditCardOutlinedIcon className='icon' />
                          {t('workspace_profile')}
                          {!!invitationWorkspaceName && <MUIBadge color="coral" size="sm" label={t('pending')} />}
                        </Link>
                      }
                      {roles.isClient && !hasUserActiveSubscription &&
                        <Link
                          to={Path.PRICING}
                          onClick={(e) => {
                            logAnalyticsEvent('Click main menu - Locked Plan Upsell', {
                              userEmail: clientData?.email,
                              organizationId: clientData?.organizationId,
                            })
                          }}
                        >
                          <LockOpenOutlinedIcon className='icon' />
                          {t('unlock_workspaces')}
                        </Link>
                      }
                      {roles.isCreative &&
                        <a href={getContentLibraryLink(i18n.language)} rel="nofollow noopener noreferrer" target="_blank">
                          <LockOpenOutlinedIcon className='icon' />
                          {t('content_library')}
                          <OpenInNewOutlinedIcon className='icon external-link' />
                        </a>
                      }
                      {isUserSet &&
                        <Link
                          to={Path.PROFILE}
                          onClick={(e) => {
                            logAnalyticsEvent('Click main menu - Profile', {
                              userEmail: clientData?.email,
                              organizationId: clientData?.organizationId,
                            })
                            closeMenuHandler(e)
                          }}
                        >
                          <PersonOutlineIcon className='icon' />
                          {t('profile')}
                        </Link>
                      }
                    </div>

                    <MUIDivider margin={12} />

                    {roles.isAdmin &&
                      <Fragment>
                        <div className='section light'>
                          <Link to={Path.API}>
                            <DataObjectOutlinedIcon className='icon' />
                            API
                          </Link>
                          <Link to={Path.ADMIN_CLIENT_ONBOARDING}>
                            <PersonAddAltOutlinedIcon className='icon' />
                            Client onboarding
                          </Link>
                          <Link to={Path.ADMIN_CREATIVE_ONBOARDING}>
                            <CameraAltOutlinedIcon className='icon' />
                            Creative onboarding
                          </Link>
                        </div>
                        <MUIDivider margin={12} />
                        <div className='section light'>
                          <a href={getHelpCenterLink(i18n.language)} rel="nofollow noopener noreferrer" target="_blank">
                            <HelpOutlineOutlinedIcon className='icon' />
                            {t('help_center')}
                            <OpenInNewOutlinedIcon className='icon external-link' />
                          </a>
                        </div>
                        <MUIDivider margin={12} />
                      </Fragment>
                    }

                    {!roles.isAdmin &&
                      <Fragment>
                        <div className='section light'>
                          <Link
                            to={Path.TERMS_AND_PRIVACY}
                            onClick={() => {
                              logAnalyticsEvent('Click main menu - Terms & Policy', {
                                userEmail: clientData?.email,
                                organizationId: clientData?.organizationId,
                              })
                            }}
                          >
                            <DescriptionOutlinedIcon className='icon' />
                            {t('terms_and_privacy')}
                          </Link>
                        </div>
                        <MUIDivider margin={12} />
                      </Fragment>
                    }

                    <div className='section light'>
                      <Link
                        to={Path.PROFILE}
                        onClick={() => {
                          logAnalyticsEvent('Click main menu - User Profile', {
                            userEmail: clientData?.email,
                            organizationId: clientData?.organizationId,
                          })
                        }}
                      >
                        <div className='user'>
                          <div className='name'>{clientData?.name ?? user.name}</div>
                          <div className='email'>{user.email}</div>
                        </div>
                      </Link>
                      <a
                        href="#logout"
                        onClick={(e) => {
                          logAnalyticsEvent('Click main menu - Log out', {
                            userEmail: clientData?.email,
                            organizationId: clientData?.organizationId,
                          })
                          handleLogout(e)
                        }}>
                        <LogoutOutlinedIcon className='icon' />
                        {t('authentication:log_out')}
                      </a>
                    </div>
                  </div>
                </Dropdown>

              </div>
              :
              <div className="login-buttons push-right">{languageDropdown}</div>
            }
          </Fragment>
        }
      </header>

      <TransitionGroup component={null}>
        {showMenu &&
          <Transition
            appear={false}
            in={showMenu}
            onEnter={(node, appears) => centerOpenEffect(node, appears)}
            onExit={(node) => centerCloseEffect(node)}
            timeout={{ enter: 450, exit: 450 }}
          >
            <div className="menu-wrap">
              <div id='mobile-main-menu' className="mobile-main-menu" ref={menuRef}>
                {!loading &&
                  <div className='menu-list'>
                    {isAuthenticated && user &&
                      <Fragment>
                        {isShowWorkspaceName &&
                          <Fragment>
                            <div className='section'>
                              <Link
                                to={Path.WORKSPACES_PROFILE.replace(':id', currentUserWorkspace?.id ?? '')}
                                onClick={(e) => {
                                  logAnalyticsEvent('Click main menu - My Plan', {
                                    userEmail: clientData?.email,
                                    organizationId: clientData?.organizationId,
                                    planId: currentUserWorkspace?.id
                                  })
                                  closeMenuHandler(e)
                                }}
                              >
                                <div className='workspace-name'>{currentUserWorkspace?.name}</div>
                              </Link>
                            </div>
                            <MUIDivider margin={16} />
                          </Fragment>
                        }
                        <div className='section'>
                          {isUserSet &&
                            <Link
                              to={Path.INDEX}
                              onClick={(e) => {
                                logAnalyticsEvent('Click main menu - Dashboard', {
                                  userEmail: clientData?.email,
                                  organizationId: clientData?.organizationId,
                                })
                                closeMenuHandler(e)
                              }}
                            >
                              <HomeOutlinedIcon className='icon' />
                              {t('dashboard')}
                            </Link>
                          }
                          <Button
                            type="secondary nobackground noborder"
                            className="button language-setting"
                            textAndIcon
                            onClick={() => {
                              logAnalyticsEvent('Click main menu - Language', {
                                userEmail: clientData?.email,
                                organizationId: clientData?.organizationId,
                              })
                              setIsLanguageOpen(!isLanguageOpen)
                            }}
                          >
                            <ChatOutlinedIcon className='icon' />
                            {t('language_settings')}
                          </Button>
                          {roles.isClient && hasUserActiveSubscription && !!activeSubscribedUserWorkspaces &&
                            <Link
                              to={Path.WORKSPACES_PROFILE.replace(':id', activeSubscribedUserWorkspaces[0].id)}
                              onClick={(e) => {
                                logAnalyticsEvent('Click main menu - My Plan', {
                                  userEmail: clientData?.email,
                                  organizationId: clientData?.organizationId,
                                  planId: activeSubscribedUserWorkspaces[0].id

                                })
                                closeMenuHandler(e)
                              }}
                            >
                              <CreditCardOutlinedIcon className='icon' />
                              {t('workspace_profile')}
                              {!!invitationWorkspaceName && <MUIBadge color="coral" size="sm" label={t('pending')} />}
                            </Link>
                          }
                          {roles.isClient && !hasUserActiveSubscription &&
                            <Link
                              to={Path.PRICING}
                              onClick={(e) => {
                                logAnalyticsEvent('Click main menu - Locked Plan Upsell', {
                                  userEmail: clientData?.email,
                                  organizationId: clientData?.organizationId,
                                })
                                closeMenuHandler(e)
                              }}
                            >
                              <LockOpenOutlinedIcon className='icon' />
                              {t('unlock_workspaces')}
                            </Link>
                          }
                          {roles.isCreative &&
                            <a href={getContentLibraryLink(i18n.language)} rel="nofollow noopener noreferrer" target="_blank">
                              <LockOpenOutlinedIcon className='icon' />
                              {t('content_library')}
                              <OpenInNewOutlinedIcon className='icon external-link' />
                            </a>
                          }
                          {isUserSet &&
                            <Link
                              to={Path.PROFILE}
                              onClick={(e) => {
                                logAnalyticsEvent('Click main menu - Profile', {
                                  userEmail: clientData?.email,
                                  organizationId: clientData?.organizationId,
                                })
                                closeMenuHandler(e)
                              }}
                            >
                              <PersonOutlineIcon className='icon' />
                              {t('profile')}
                            </Link>
                          }
                        </div>

                        <MUIDivider margin={16} />

                        {roles.isAdmin &&
                          <Fragment>
                            <div className='section light'>
                              <Link to="/API" onClick={closeMenuHandler}>
                                <DataObjectOutlinedIcon className='icon' />
                                API
                              </Link>
                              <Link to={Path.ADMIN_CLIENT_ONBOARDING} onClick={closeMenuHandler}>
                                <PersonAddAltOutlinedIcon className='icon' />
                                Client onboarding
                              </Link>
                              <Link to={Path.ADMIN_CREATIVE_ONBOARDING} onClick={closeMenuHandler}>
                                <CameraAltOutlinedIcon className='icon' />
                                Creative onboarding
                              </Link>
                            </div>
                            <MUIDivider margin={16} />
                            <div className='section light'>
                              <a href={getHelpCenterLink(i18n.language)} rel="nofollow noopener noreferrer" target="_blank">
                                <HelpOutlineOutlinedIcon className='icon' />
                                {t('help_center')}
                                <OpenInNewOutlinedIcon className='icon external-link' />
                              </a>
                            </div>
                          </Fragment>
                        }

                        {!roles.isAdmin &&
                          <Fragment>
                            <div className='section light'>
                              {isUserSet &&
                                <>
                                  <a
                                    href={getHelpCenterLink(i18n.language)}
                                    rel="nofollow noopener noreferrer"
                                    target="_blank"
                                    onClick={() =>
                                      logAnalyticsEvent('Click main menu - Help Center', {
                                        userEmail: clientData?.email,
                                        organizationId: clientData?.organizationId,
                                      })
                                    }
                                  >
                                    <HelpOutlineOutlinedIcon className='icon' />
                                    {t('help_center')}
                                    <OpenInNewOutlinedIcon className='icon external-link' />
                                  </a>
                                  <Button
                                    type="secondary nobackground noborder"
                                    className="button contact-us"
                                    textAndIcon
                                    onClick={() => {
                                      logAnalyticsEvent('Click main menu - Contact Us', {
                                        userEmail: clientData?.email,
                                        organizationId: clientData?.organizationId,
                                      })
                                      setIsContactUsOpen(!isContactUsOpen)
                                    }}
                                  >
                                    <LocalPhoneOutlinedIcon className='icon' />
                                    {t('contact_us')}
                                  </Button>
                                </>
                              }
                              <Link
                                to={Path.TERMS_AND_PRIVACY}
                                onClick={(e) => {
                                  logAnalyticsEvent('Click main menu - Terms & Policy', {
                                    userEmail: clientData?.email,
                                    organizationId: clientData?.organizationId,
                                  })
                                  closeMenuHandler(e)
                                }}
                              >
                                <DescriptionOutlinedIcon className='icon' />
                                {t('terms_and_privacy')}
                              </Link>
                            </div>
                          </Fragment>
                        }

                        <div className='footer'>
                          {(roles.isClient || roles.isAdmin) &&
                            <Button
                              type="primary"
                              className='menu-order-visuals-button'
                              onClick={(e) => {
                                logAnalyticsEvent('Click main menu - Order new visuals', {
                                  userEmail: clientData?.email,
                                  organizationId: clientData?.organizationId,
                                })
                                navigate(Path.PURCHASE_FLOW)
                                closeMenuHandler(e)
                              }}
                            >
                              {t('dashboard_client:call_to_action_order.button')}
                            </Button>
                          }

                          <MUIDivider margin={16} />

                          <div className='section light user-block'>
                            <Link
                              to={Path.PROFILE}
                              onClick={(e) => {
                                logAnalyticsEvent('Click main menu - User Profile', {
                                  userEmail: clientData?.email,
                                  organizationId: clientData?.organizationId,
                                })
                                closeMenuHandler(e)
                              }}
                            >
                              <div className='user'>
                                <div className='name'>{clientData?.name ?? user.name}</div>
                                <div className='email'>{user.email}</div>
                              </div>
                            </Link>
                            <a
                              href="#logout"
                              onClick={(e) => {
                                logAnalyticsEvent('Click main menu - Log out', {
                                  userEmail: clientData?.email,
                                  organizationId: clientData?.organizationId,
                                })
                                handleLogout(e)
                              }}>
                              <LogoutOutlinedIcon className='icon' />
                              {t('authentication:log_out')}
                            </a>
                          </div>
                        </div>
                      </Fragment>
                    }
                  </div>
                }
              </div>
            </div>
          </Transition>
        }
      </TransitionGroup>

    </Fragment>
  )
}

export default TopBar
