import { Box, Stack, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Download } from '@mui/icons-material'
import { MUIButton } from '../MUIButton'
import { SectionedBorderBox } from '../SectionedBorderBox'

interface Props {
  /** Title for the section box */
  boxTitle?: ReactNode | string
  /** Slot for the upload component */
  uploadSlot: ReactNode
  /** Variant of component */
  variant: 'owners' | 'representatives'
  /** Url of sample */
  sampleUrl: string
}

export const PowerOfAttorneyUpload: FC<Props> = ({
  boxTitle,
  uploadSlot,
  sampleUrl,
  variant,
}) => {
  const { t } = useTranslation(['common', 'power_of_attorney'])

  return (
    <SectionedBorderBox title={boxTitle || t(`power_of_attorney:upload_box_title_${variant}`)}>
      <Stack direction="row" flexWrap="wrap" gap={2} >

        <Stack
          flex="1 1 30%"
          direction="column"
          gap={1}
        >

          <Typography variant="h3" sx={{ margin: '0', lineHeight: 'normal' }}>
            <Trans parent={null}>
              {t('power_of_attorney:upload_content_title')}
            </Trans>
          </Typography>

          <Typography variant="text-sm" flex="1 0 auto">
            <Trans parent={null}>
              {t(`power_of_attorney:upload_content_description_${variant}`)}
            </Trans>
          </Typography>

          <Box sx={{ mt: 2 }}>
            <MUIButton
              startIcon={<Download />}
              onClick={() => window.open(sampleUrl, '__blank', 'noopener noreferrer nofollow')}
            >
              {t('Download')}
            </MUIButton>
          </Box>

        </Stack>

        <Box
          flex="1 0 200px"
          sx={{
            border: '2px dashed gray',
            borderRadius: '1.6rem',
            minHeight: '200px',
          }}
        >
          {uploadSlot}
        </Box>

      </Stack>
    </SectionedBorderBox>
  )
}
