import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'
import { FloorPlanTemplateDetailsDTO, FloorPlanTemplatesDTO } from 'models/purchaseFlow'

import { AxiosResponse } from 'axios'
import { SignedURLDTO } from 'models/visuals'
import { useAPI } from 'utils/API'
import { useQuery } from '@tanstack/react-query'

enum Endpoints {
  GET_FLOOR_PLAN_TEMPLATES = '/workspace/floorPlanTemplates',
  GET_FLOOR_PLAN_TEMPLATE_DETAILS = '/workspace/floorPlanTemplate/{templateId}',
  GET_FLOOR_PLAN_TEMPLATE_LOGO = '/workspace/{workspaceId}/assets/floorPlanLogo/{logoId}',
  ADMIN_GET_FLOOR_PLAN_TEMPLATES = '/workspace/floorPlanTemplates/user/{userId}',
  ADMIN_GET_FLOOR_PLAN_TEMPLATE_DETAILS = '/workspace/floorPlanTemplate/{templateId}/user/{userId}',
}

// QUERIES

export function useGetFloorPlanTemplates() {
  const api = useAPI<Endpoints>()

  return useQuery<FloorPlanTemplatesDTO[], Error>({
    queryKey: [EntityKeys.FLOOR_PLAN_TEMPLATES, QueryType.LIST],
    queryFn: () => api.get<FloorPlanTemplatesDTO[]>(
      Endpoints.GET_FLOOR_PLAN_TEMPLATES,
      {},
      false
    )
  })
}

export function useAdminGetFloorPlanTemplates(userId: string) {
  const api = useAPI<Endpoints>()

  return useQuery<FloorPlanTemplatesDTO[], Error>({
    queryKey: [EntityKeys.FLOOR_PLAN_TEMPLATES, QueryType.LIST, { userId }],
    queryFn: () => api.get<FloorPlanTemplatesDTO[]>(
      Endpoints.ADMIN_GET_FLOOR_PLAN_TEMPLATES,
      { userId },
      false
    )
  })
}

// MUTATIONS

export function useGetFloorPlanTemplateDetails() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<FloorPlanTemplateDetailsDTO>, { templateId: string }>(
    ({ templateId }) => api.get(
      Endpoints.GET_FLOOR_PLAN_TEMPLATE_DETAILS,
      { templateId },
      true
    )
  )
}

export function useAdminGetFloorPlanTemplateDetails() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<FloorPlanTemplateDetailsDTO>, { templateId: string, userId: string }>(
    ({ templateId, userId }) => api.get(
      Endpoints.ADMIN_GET_FLOOR_PLAN_TEMPLATE_DETAILS,
      { templateId, userId },
      true
    )
  )
}

export function useGetFloorPlanTemplateLogo() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<SignedURLDTO>, { logoId: string, workspaceId: string }>(
    ({ logoId, workspaceId }) => api.get(
      Endpoints.GET_FLOOR_PLAN_TEMPLATE_LOGO,
      { logoId, workspaceId },
      true
    )
  )
}
