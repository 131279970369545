import { useCallback, useEffect, useMemo, useState } from 'react'

import { KeyboardEventKey } from 'constants/misc'
import { MUIButton } from 'components/common/MUIButton'
import { MUIInputField } from 'components/common/MUIInputField'
import Modal from 'components/common/Modals/Modal/Modal'
import { Nullable } from 'models/helpers'
import { Path } from 'constants/router'
import { Stack } from '@mui/material'
import classnames from 'classnames'
import isEmail from 'validator/lib/isEmail'
import popupStyles from 'components/styles/popup.module.sass'
import styles from './ChangeEmailPopup.module.sass'
import { useAuth0 } from 'utils/auth'
import { useChangeEmail } from 'dataQueries'
import { useNavigate } from 'react-router'
import { useSnackbar } from 'components/contexts/SnackbarService.context'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props Input properties
 */
interface Props {
  /** Decides when is modal open */
  isOpen: boolean
  /** Onclick action triggered when user clicks outside the .modal-content element */
  onClickOutside?: (e: React.MouseEvent) => unknown
  /** onKeyDown action triggered when user presses any key on the keyboard */
  onKeyDown?: (e: React.KeyboardEvent) => unknown
  /** Onclick action triggered when user clicks the close button */
  onClickClose?: (e: React.MouseEvent) => unknown
}

/**
 * @component Change email popup
 * @example
 * <ChangeEmailPopup isOpen={true} onClickOutside={() => {}} />
 */
export const ChangeEmailPopup: React.FC<Props> = ({
  isOpen,
  onClickOutside,
  onKeyDown,
  onClickClose,
}) => {
  const { t } = useTranslation(['profile'])

  const changeEmail = useChangeEmail()
  const { spawnSuccessToast } = useSnackbar()

  const navigate = useNavigate()
  const { roles } = useAuth0()

  const [email, setEmail] = useState<string>('')
  const [isEmailValid, setIsEmailValid] = useState<Nullable<string>>(null)

  const isSubmitButtonDisabled = useMemo(() => !email || !!isEmailValid || changeEmail.isPending, [changeEmail.isPending, email, isEmailValid])

  const onChangeEmail = useCallback(() => {
    changeEmail.mutate(
      { email },
      {
        onSuccess: () => {
          spawnSuccessToast(t('change_email.success'))
          setTimeout(() => { navigate({ pathname: Path.EMAIL_VERIFICATION, search: '?mode=change-email' }) }, 2000)
        }
      }
    )
    // Omit spawnSuccessToast, t, dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeEmail, email, navigate])

  const resetEmailChange = useCallback(() => {
    changeEmail.reset()
    setEmail('')
    setIsEmailValid(null)
  }, [changeEmail])

  const onOutsideOrClose = useCallback((e: React.MouseEvent<Element, MouseEvent>) => {
    onClickOutside?.(e)
    onClickClose?.(e)
    resetEmailChange()
  }, [resetEmailChange, onClickOutside, onClickClose])

  const handleEmailParse = useCallback((inputString: string) => {
    setIsEmailValid(null)
    const trimmedString = inputString.trim()
    if (!trimmedString) return
    if (!isEmail(trimmedString)) {
      setIsEmailValid(t('change_email.invalid_email'))
    }
  }, [t])

  const onKeyboardEvent = useCallback((e: React.KeyboardEvent) => {
    onKeyDown?.(e)

    switch (e.key) {
      case KeyboardEventKey.ESCAPE:
        if (onkeydown) resetEmailChange()
        break
      case KeyboardEventKey.ENTER:
        e.preventDefault()
        handleEmailParse(email)
        break

      default:
        break
    }
  }, [onKeyDown, resetEmailChange, handleEmailParse, email])

  useEffect(() => {
    const timer = setTimeout(() => {
      handleEmailParse(email)
    }, 2000)

    return () => clearTimeout(timer)
  }, [email, handleEmailParse])

  return (
    <Modal
      className={classnames(styles.changeEmailPopup)}
      isOpen={isOpen}
      modalContentClassName={classnames(styles.content, (changeEmail.isSuccess || changeEmail.isError) ? styles.successContent : undefined)}
      onKeyDown={onKeyboardEvent}
      onClose={onOutsideOrClose}
      title={t('change_email.popup_title')}
      subtitle={roles.isClient ? t('change_email.popup_subtitle') : undefined}
      footerContent={!(changeEmail.isSuccess || changeEmail.isError) &&
        <Stack gap={.8} flexDirection="row" justifyContent="flex-end" flexWrap="wrap" marginBottom="0.8rem">

          <MUIButton type="secondaryNoBorder" onClick={onOutsideOrClose}>
            {t('change_email.popup_cancel')}
          </MUIButton>

          <MUIButton onClick={onChangeEmail} disabled={isSubmitButtonDisabled} isLoading={changeEmail.isPending}>
            {t('change_email.popup_confirm')}
          </MUIButton>

        </Stack>
      }
      queryData={changeEmail}
    >

      <div className={popupStyles.inside}>
        <Stack gap={1.6} marginBottom={2.4} marginTop={.7}>
          <MUIInputField
            label={t('change_email.popup_input_title')}
            value={email}
            hintText={t('change_email.popup_input_note')}
            isError={!!isEmailValid}
            errorText={isEmailValid}
            onBlur={() => handleEmailParse(email)}
            onChange={e => setEmail(e.target.value)}
            disabled={changeEmail.isPending}
          />
        </Stack>
      </div>

    </Modal>
  )
}
