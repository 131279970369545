import { ActionAPI, ActionTupleType } from 'models/redux'

import { ActionTypeAPIData } from 'constants/redux'
import { defaultAPIDataState } from '.'
import { reduceEditMissionAdminCommentsForEditor } from '../Admin/EditMissionAdminCommentsForEditor'
import { reduceEditMissionCreativeInstructionsFromAdmin } from '../Admin/EditMissionCreativeInstructionsFromAdmin'
import { reduceEditMissionInternalComments } from '../Admin/EditMissionInternalComments'
import { reduceGetUpsellPaymentIntent } from '../Mission/GetUpsellPaymentIntent'
import { reduceAutomateOrder } from '../Order/AutomateOrder/automateOrder.reducers'
import { reduceStopAutomatedOrder } from '../Order/StopAutomatedOrder/stopAutomatedOrder.reducers'
import { reduceOrganizationMe } from '../Organization/OrganizationMe'
import { reduceSearchOrganizations } from '../Organization/SearchOrganizations'
import { reduceGetSubscriptionCheckoutLink } from '../Subscriptions/GetCheckoutLink'
import { reduceChangeLocale } from '../User/ChangeLocale'
import { reduceGetUserWorkspaces } from '../User/GetUserWorkspaces'
import { reduceUpdatePreferredPaymentMethod } from '../User/UpdatePreferredPaymentMethod'
import { reduceUpdateUserMe } from '../User/UpdateUserMe'
import { reduceUserMe } from '../User/UserMe'
import { reduceUserMeAlternative } from '../User/UserMeAlternative'
import { reduceUserMeta } from '../User/UserMeta'
import { reduceArchiveVisuals } from '../Visual/ArchiveVisuals/ArchiveVisuals.reducers'
import { reduceDeleteVirtualVisit } from '../Visual/DeleteVirtualVisit'
import { reduceDownloadArchiveVisuals } from '../Visual/DownloadArchive'
import { reduceExtendVirtualVisitHosting } from '../Visual/ExtendVirtualVisitHosting'
import { reduceListPurchaseSessionVisuals } from '../Visual/ListPurchaseSessionVisuals'
import { reduceListVisuals } from '../Visual/ListVisuals'
import { reduceLoadPurchaseSessionVisual } from '../Visual/LoadPurchaseSessionVisual'
import { reduceLoadVisual } from '../Visual/LoadVisual'
import { reduceSelectVisuals } from '../Visual/SelectVisuals'
import { reduceSelectVisualsForStaging } from '../Visual/SelectVisualsForStaging'
import { reduceArchiveEditingVisuals } from '../VisualsEditing/ArchivedEditingVisuals'
import { reduceLoadEditedVisual } from '../VisualsEditing/LoadEditedVisuals'
import { reduceLoadWatermarkLogo } from '../VisualsEditing/LoadWatermarkLogo'
import { reduceUploadWatermarkLogo } from '../VisualsEditing/UploadWatermarkLogo/uploadWatermarkLogo.reducers'
import { reduceAddWorkspaceMembers } from '../Workspace/AddWorkspaceMembers'
import { reduceCreateWorkspace } from '../Workspace/createWorkspace'
import { reduceCreateWorkspaceSubscription } from '../Workspace/CreateWorkspaceSubscription'

/** Redux api data reducer */
export const APIDataReducer = (state = defaultAPIDataState, action: ActionAPI) => {
  if (!Array.isArray(action.type as ActionTupleType)) return state
  const dataType = action.type[1]

  switch (dataType) {
    // User
    case ActionTypeAPIData.USER_CHANGE_LOCALE:
      return { ...state, [ActionTypeAPIData.USER_CHANGE_LOCALE]: reduceChangeLocale(state[ActionTypeAPIData.USER_CHANGE_LOCALE], action) }
    case ActionTypeAPIData.USER_ME:
    case ActionTypeAPIData.UPDATE_USER_ME_STORE_DATA:
      return { ...state, [ActionTypeAPIData.USER_ME]: reduceUserMe(state[ActionTypeAPIData.USER_ME], action) }
    case ActionTypeAPIData.UPDATE_USER_ME:
      return { ...state, [ActionTypeAPIData.UPDATE_USER_ME]: reduceUpdateUserMe(state[ActionTypeAPIData.UPDATE_USER_ME], action) }
    case ActionTypeAPIData.USER_ME_ALTERNATIVE:
      return { ...state, [ActionTypeAPIData.USER_ME_ALTERNATIVE]: reduceUserMeAlternative(state[ActionTypeAPIData.USER_ME_ALTERNATIVE], action) }
    case ActionTypeAPIData.USER_META:
      return { ...state, [ActionTypeAPIData.USER_META]: reduceUserMeta(state[ActionTypeAPIData.USER_META], action) }
    case ActionTypeAPIData.UPDATE_PREFERRED_PAYMENT_METHOD:
      return { ...state, [ActionTypeAPIData.UPDATE_PREFERRED_PAYMENT_METHOD]: reduceUpdatePreferredPaymentMethod(state[ActionTypeAPIData.UPDATE_PREFERRED_PAYMENT_METHOD], action) }
    case ActionTypeAPIData.USER_GET_WORKSPACES:
      return { ...state, [ActionTypeAPIData.USER_GET_WORKSPACES]: reduceGetUserWorkspaces(state[ActionTypeAPIData.USER_GET_WORKSPACES], action) }

    // Organization
    case ActionTypeAPIData.ORGANIZATION_ME:
      return { ...state, [ActionTypeAPIData.ORGANIZATION_ME]: reduceOrganizationMe(state[ActionTypeAPIData.ORGANIZATION_ME], action) }
    case ActionTypeAPIData.SEARCH_ORGANIZATIONS:
      return { ...state, [ActionTypeAPIData.SEARCH_ORGANIZATIONS]: reduceSearchOrganizations(state[ActionTypeAPIData.SEARCH_ORGANIZATIONS], action) }

    // Workspace
    case ActionTypeAPIData.CREATE_WORKSPACE:
      return { ...state, [ActionTypeAPIData.CREATE_WORKSPACE]: reduceCreateWorkspace(state[ActionTypeAPIData.CREATE_WORKSPACE], action) }
    case ActionTypeAPIData.ADD_WORKSPACE_MEMBERS:
      return { ...state, [ActionTypeAPIData.ADD_WORKSPACE_MEMBERS]: reduceAddWorkspaceMembers(state[ActionTypeAPIData.ADD_WORKSPACE_MEMBERS], action) }
    case ActionTypeAPIData.CREATE_WORKSPACE_SUBSCRIPTION:
      return { ...state, [ActionTypeAPIData.CREATE_WORKSPACE_SUBSCRIPTION]: reduceCreateWorkspaceSubscription(state[ActionTypeAPIData.CREATE_WORKSPACE_SUBSCRIPTION], action) }

    // Mission
    case ActionTypeAPIData.GET_UPSELL_PAYMENT_INTENT:
      return { ...state, [ActionTypeAPIData.GET_UPSELL_PAYMENT_INTENT]: reduceGetUpsellPaymentIntent(state[ActionTypeAPIData.GET_UPSELL_PAYMENT_INTENT], action) }

    // Order
    case ActionTypeAPIData.AUTOMATE_ORDER:
      return { ...state, [ActionTypeAPIData.AUTOMATE_ORDER]: reduceAutomateOrder(state[ActionTypeAPIData.AUTOMATE_ORDER], action) }
    case ActionTypeAPIData.STOP_AUTOMATED_ORDER:
      return { ...state, [ActionTypeAPIData.STOP_AUTOMATED_ORDER]: reduceStopAutomatedOrder(state[ActionTypeAPIData.STOP_AUTOMATED_ORDER], action) }

    // Subscriptions
    case ActionTypeAPIData.GET_SUBSCRIPTION_CHECKOUT_LINK:
      return { ...state, [ActionTypeAPIData.GET_SUBSCRIPTION_CHECKOUT_LINK]: reduceGetSubscriptionCheckoutLink(state[ActionTypeAPIData.GET_SUBSCRIPTION_CHECKOUT_LINK], action) }

    // Visual
    case ActionTypeAPIData.LIST_VISUALS:
      return { ...state, [ActionTypeAPIData.LIST_VISUALS]: reduceListVisuals(state[ActionTypeAPIData.LIST_VISUALS], action) }
    case ActionTypeAPIData.LIST_PURCHASE_SESSION_VISUALS:
      return { ...state, [ActionTypeAPIData.LIST_PURCHASE_SESSION_VISUALS]: reduceListPurchaseSessionVisuals(state[ActionTypeAPIData.LIST_PURCHASE_SESSION_VISUALS], action) }
    case ActionTypeAPIData.LOAD_VISUAL:
      return { ...state, [ActionTypeAPIData.LOAD_VISUAL]: reduceLoadVisual(state[ActionTypeAPIData.LOAD_VISUAL], action) }
    case ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL:
      return { ...state, [ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL]: reduceLoadPurchaseSessionVisual(state[ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL], action) }
    case ActionTypeAPIData.SELECT_VISUALS:
      return { ...state, [ActionTypeAPIData.SELECT_VISUALS]: reduceSelectVisuals(state[ActionTypeAPIData.SELECT_VISUALS], action) }
    case ActionTypeAPIData.ARCHIVE_VISUALS:
      return { ...state, [ActionTypeAPIData.ARCHIVE_VISUALS]: reduceArchiveVisuals(state[ActionTypeAPIData.ARCHIVE_VISUALS], action) }
    case ActionTypeAPIData.ARCHIVE_EDITING_VISUALS:
      return { ...state, [ActionTypeAPIData.ARCHIVE_EDITING_VISUALS]: reduceArchiveEditingVisuals(state[ActionTypeAPIData.ARCHIVE_EDITING_VISUALS], action) }
    case ActionTypeAPIData.DOWNLOAD_ARCHIVE:
      return { ...state, [ActionTypeAPIData.DOWNLOAD_ARCHIVE]: reduceDownloadArchiveVisuals(state[ActionTypeAPIData.DOWNLOAD_ARCHIVE], action) }
    case ActionTypeAPIData.DELETE_VIRTUAL_VISIT:
      return { ...state, [ActionTypeAPIData.DELETE_VIRTUAL_VISIT]: reduceDeleteVirtualVisit(state[ActionTypeAPIData.DELETE_VIRTUAL_VISIT], action) }
    case ActionTypeAPIData.EXTEND_VIRTUAL_VISIT_HOSTING:
      return { ...state, [ActionTypeAPIData.EXTEND_VIRTUAL_VISIT_HOSTING]: reduceExtendVirtualVisitHosting(state[ActionTypeAPIData.EXTEND_VIRTUAL_VISIT_HOSTING], action) }
    case ActionTypeAPIData.SELECT_VISUALS_FOR_STAGING:
      return { ...state, [ActionTypeAPIData.SELECT_VISUALS_FOR_STAGING]: reduceSelectVisualsForStaging(state[ActionTypeAPIData.SELECT_VISUALS_FOR_STAGING], action) }

    // Visuals Editing
    case ActionTypeAPIData.LOAD_WATERMARK_LOGO:
      return { ...state, [ActionTypeAPIData.LOAD_WATERMARK_LOGO]: reduceLoadWatermarkLogo(state[ActionTypeAPIData.LOAD_WATERMARK_LOGO], action) }
    case ActionTypeAPIData.UPLOAD_WATERMARK_LOGO:
      return { ...state, [ActionTypeAPIData.UPLOAD_WATERMARK_LOGO]: reduceUploadWatermarkLogo(state[ActionTypeAPIData.UPLOAD_WATERMARK_LOGO], action) }
    case ActionTypeAPIData.LOAD_EDITED_VISUAL:
      return { ...state, [ActionTypeAPIData.LOAD_EDITED_VISUAL]: reduceLoadEditedVisual(state[ActionTypeAPIData.LOAD_EDITED_VISUAL], action) }

    // Admin
    case ActionTypeAPIData.EDIT_ASSIGNMENT_ADMIN_COMMENTS_FOR_EDITOR:
      return { ...state, [ActionTypeAPIData.EDIT_ASSIGNMENT_ADMIN_COMMENTS_FOR_EDITOR]: reduceEditMissionAdminCommentsForEditor(state[ActionTypeAPIData.EDIT_ASSIGNMENT_ADMIN_COMMENTS_FOR_EDITOR], action) }
    case ActionTypeAPIData.EDIT_ASSIGNMENT_INTERNAL_COMMENTS:
      return { ...state, [ActionTypeAPIData.EDIT_ASSIGNMENT_INTERNAL_COMMENTS]: reduceEditMissionInternalComments(state[ActionTypeAPIData.EDIT_ASSIGNMENT_INTERNAL_COMMENTS], action) }
    case ActionTypeAPIData.EDIT_ASSIGNMENT_CREATIVE_INSTRUCTIONS_FROM_ADMIN:
      return { ...state, [ActionTypeAPIData.EDIT_ASSIGNMENT_CREATIVE_INSTRUCTIONS_FROM_ADMIN]: reduceEditMissionCreativeInstructionsFromAdmin(state[ActionTypeAPIData.EDIT_ASSIGNMENT_CREATIVE_INSTRUCTIONS_FROM_ADMIN], action) }

    // Unmatched
    default:
      return state
  }
}
