import { APIDataStore, StatusResponseBody } from 'models/redux'

import { ActionTypeAPIData } from 'constants/redux'
import { APIRequest } from 'models/API'

/** The default redux api data state */
export const defaultAPIDataState: APIDataStore = {
  // User
  [ActionTypeAPIData.USER_CHANGE_LOCALE]: new APIRequest<null>(),
  [ActionTypeAPIData.USER_ME]: new APIRequest<null>(),
  [ActionTypeAPIData.UPDATE_USER_ME]: new APIRequest<null>(),
  [ActionTypeAPIData.USER_ME_ALTERNATIVE]: new APIRequest<null>(),
  [ActionTypeAPIData.USER_META]: new APIRequest<null>(),
  [ActionTypeAPIData.UPDATE_PREFERRED_PAYMENT_METHOD]: {
    request: new APIRequest<null>(),
    paymentMethod: undefined,
  },
  [ActionTypeAPIData.USER_GET_WORKSPACES]: new APIRequest<null>(),

  // Organization
  [ActionTypeAPIData.ORGANIZATION_ME]: new APIRequest<null>(),
  [ActionTypeAPIData.SEARCH_ORGANIZATIONS]: [],

  // Workspace
  [ActionTypeAPIData.CREATE_WORKSPACE]: new APIRequest(),
  [ActionTypeAPIData.ADD_WORKSPACE_MEMBERS]: {},
  [ActionTypeAPIData.CREATE_WORKSPACE_SUBSCRIPTION]: new APIRequest(),

  // Mission
  [ActionTypeAPIData.GET_UPSELL_PAYMENT_INTENT]: {},

  // Order
  [ActionTypeAPIData.AUTOMATE_ORDER]: {},
  [ActionTypeAPIData.STOP_AUTOMATED_ORDER]: {},

  // Subscriptions
  [ActionTypeAPIData.GET_SUBSCRIPTION_CHECKOUT_LINK]: new APIRequest(),

  // Visual
  [ActionTypeAPIData.LIST_VISUALS]: {},
  [ActionTypeAPIData.LIST_PURCHASE_SESSION_VISUALS]: {},
  [ActionTypeAPIData.LOAD_VISUAL]: {},
  [ActionTypeAPIData.LOAD_PURCHASE_SESSION_VISUAL]: {},
  [ActionTypeAPIData.SELECT_VISUALS]: {},
  [ActionTypeAPIData.ARCHIVE_VISUALS]: {},
  [ActionTypeAPIData.ARCHIVE_EDITING_VISUALS]: {},
  [ActionTypeAPIData.DOWNLOAD_ARCHIVE]: {},
  [ActionTypeAPIData.DELETE_VIRTUAL_VISIT]: {},
  [ActionTypeAPIData.EXTEND_VIRTUAL_VISIT_HOSTING]: {},
  [ActionTypeAPIData.SELECT_VISUALS_FOR_STAGING]: {},

  // Visual Editing
  [ActionTypeAPIData.LOAD_WATERMARK_LOGO]: new APIRequest(),
  [ActionTypeAPIData.UPLOAD_WATERMARK_LOGO]: {
    request: new APIRequest<null | StatusResponseBody>(),
    progress: 0,
    file: new File([], ''),
    signedUrl: '',
    workspaceId: '',
  },
  [ActionTypeAPIData.LOAD_EDITED_VISUAL]: {},

  // Admin
  [ActionTypeAPIData.EDIT_ASSIGNMENT_ADMIN_COMMENTS_FOR_EDITOR]: {},
  [ActionTypeAPIData.EDIT_ASSIGNMENT_INTERNAL_COMMENTS]: {},
  [ActionTypeAPIData.EDIT_ASSIGNMENT_CREATIVE_INSTRUCTIONS_FROM_ADMIN]: {},
}
