import { PurchaseFlowStepKey, usePurchaseFlowUtilities } from '../../_main/contexts'

import { DynamicFlowStep } from 'components/common/DynamicFlow'
import { FC } from 'react'
import { PropertyInformationStepController } from './PropertyInformationStep.controller'
import { StepWrapper } from '../../common/StepWrapper'
import { useTranslation } from 'react-i18next'

/**
 * @component
 * Page in PurchaseFlow step for specifying Property Information.
 * 
 * @example 
 * <PropertyInformationStep />
 */
export const PropertyInformationStep: FC = () => {
  const { t } = useTranslation(['purchase_flow'])
  const { isPropertyInformationStepValid } = usePurchaseFlowUtilities()

  return (
    <DynamicFlowStep
      stepKey={PurchaseFlowStepKey.PROPERTY_INFORMATION}
      isValid={isPropertyInformationStepValid}
    >
      <StepWrapper
        title={t('property_information_step.section_title')}
        subtitle={t('property_information_step.section_description')}
      >
        <PropertyInformationStepController />
      </StepWrapper>
    </DynamicFlowStep>
  )
}
