import { ActiveSubscriptionStatuses, PendingInvitation, SubscriptionPlan, WorkspaceMember, WorkspaceRole } from 'models/user'
import { useCallback, useMemo, useState } from 'react'

import { ChangeMemberData } from './WorkspacesProfile.controller'
import { MemberDrawerType } from '../WorkspaceProfileSettingDrawer'
import { Nullable } from 'models/helpers'
import constate from 'constate'
import { getDateString } from 'utils/time'
import { useGetUserWorkspaces } from 'dataQueries'
import { useTimezone } from 'components/contexts/timezone.context'
import { useUserData } from 'components/contexts/UserDataContext'
import { workspacePlanType } from 'constants/workspaces'

/** Hook setup with Workspaces Profile logic to be used with constate */
export const [WorkspacesProfileContextProvider, useWorkspacesProfileContext] = constate(({ workspaceId }: { workspaceId: string }) => {

  const { baseUserData } = useUserData()
  const { userTimezone } = useTimezone()

  // Data to all workspaces the user is a member of
  const allUserWorkspacesResponse = useGetUserWorkspaces()
  const allUserWorkspacesData = useMemo(() => allUserWorkspacesResponse.data?.workspaces, [allUserWorkspacesResponse])
  const allUserNonPersonalWorkspacesData = useMemo(() => allUserWorkspacesData && allUserWorkspacesData.filter(workspace => workspace.plan !== SubscriptionPlan.PERSONAL) || [], [allUserWorkspacesData])

  // Current workspace profile data
  const workspaceData = useMemo(() => allUserNonPersonalWorkspacesData.find(workspace => workspace.workspaceId === workspaceId), [allUserNonPersonalWorkspacesData, workspaceId])
  const workspaceDataOfUser = useMemo(() => workspaceData?.members.find(member => member.userId === baseUserData?.id), [workspaceData, baseUserData])
  const workspaceCreationDate = useMemo(() => workspaceData && getDateString(workspaceData.creationDate, { timezone: userTimezone }), [workspaceData, userTimezone])
  const sortedWorkspaceMembers = useMemo(() => workspaceData && workspaceData.members.sort((a, b) => {
    const memberA = a.name.toLowerCase()
    const memberB = b.name.toLowerCase()
    if (memberA > memberB) return 1
    if (memberA < memberB) return -1
    return 0
  }), [workspaceData])
  const workspaceMembersWithAdminRole = useMemo(() => sortedWorkspaceMembers && sortedWorkspaceMembers.filter(member => member.role === WorkspaceRole.ADMIN), [sortedWorkspaceMembers])
  const isSingleAdminInWorkspace = useMemo(() => workspaceMembersWithAdminRole?.length === 1, [workspaceMembersWithAdminRole])
  const hasWorkspaceActiveSubscription = useMemo(() => workspaceData && ActiveSubscriptionStatuses.has(workspaceData.workspaceStatus), [workspaceData])
  const workspaceMembersWithMemberRole = useMemo(() => sortedWorkspaceMembers && sortedWorkspaceMembers.filter(member => member.role === WorkspaceRole.MEMBER), [sortedWorkspaceMembers])
  const workspaceMembersEmails = useMemo(() => sortedWorkspaceMembers?.map(member => member.email), [sortedWorkspaceMembers])
  const pendingInvitations = useMemo(() => workspaceData && workspaceData.pendingInvitations, [workspaceData])
  const isWorkspaceEnterprise = useMemo(() => (workspaceData && workspacePlanType[workspaceData.plan]) === SubscriptionPlan.ENTERPRISE, [workspaceData])
  const userWorkspaceInvitedData = useMemo(() => pendingInvitations?.find((member) => member.email === baseUserData?.email), [baseUserData?.email, pendingInvitations])
  const userWorkspaceInvitedBy = useMemo(() => sortedWorkspaceMembers?.find((member) => member.userId === userWorkspaceInvitedData?.invitedBy), [sortedWorkspaceMembers, userWorkspaceInvitedData?.invitedBy])

  // member setting control
  const [addMembersPopup, setAddMembersPopup] = useState(false)
  const [removeMember, setRemoveMember] = useState<WorkspaceMember['email'] | undefined>(undefined)
  const [changeMemberRole, setChangeMemberRole] = useState<Nullable<ChangeMemberData>>(null)
  const [memberSetting, setMemberSetting] = useState<Nullable<WorkspaceMember>>(null)
  const [pendingInvitationSetting, setPendingInvitationSetting] = useState<Nullable<PendingInvitation>>(null)
  const [memberDrawerType, setMemberDrawerType] = useState<Nullable<MemberDrawerType>>(null)
  const [isSettingDrawerOpen, setIsSettingDrawerOpen] = useState(false)

  const resetDrawer = useCallback(() => {
    setMemberSetting(null)
    setPendingInvitationSetting(null)
    setMemberDrawerType(null)
  }, [])

  return {
    workspaceId,
    allUserWorkspacesResponse,
    allUserWorkspacesData,
    allUserNonPersonalWorkspacesData,
    workspaceData,
    workspaceDataOfUser,
    workspaceCreationDate,
    sortedWorkspaceMembers,
    workspaceMembersWithAdminRole,
    isSingleAdminInWorkspace,
    hasWorkspaceActiveSubscription,
    workspaceMembersWithMemberRole,
    workspaceMembersEmails,
    isWorkspaceEnterprise,
    pendingInvitations,
    userWorkspaceInvitedData,
    userWorkspaceInvitedBy,
    addMembersPopup,
    setAddMembersPopup,
    removeMember,
    setRemoveMember,
    changeMemberRole,
    setChangeMemberRole,
    memberSetting,
    setMemberSetting,
    pendingInvitationSetting,
    setPendingInvitationSetting,
    memberDrawerType,
    setMemberDrawerType,
    isSettingDrawerOpen,
    setIsSettingDrawerOpen,
    resetDrawer
  }
})
