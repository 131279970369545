import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { DocumentCategory, EditingCategory, NoUploadEditingKinds, ProductKind, UnlimitedUploadKinds } from 'constants/product'
import { InstructionStep, StyleSelectionStep } from '../../steps'
import { To, useNavigate } from 'react-router-dom'
import { isAddressValid, isDocumentCategory, isEditingCategory, isShootingCategory } from 'utils/validators'
import { useCallback, useEffect, useMemo } from 'react'

import { FeatureFlag } from 'utils/featureFlags'
import { FloorPlanConfigStep } from '../../steps/FloorPlanConfig'
import { Path } from 'constants/router'
import { PowerOfAttorneyStep } from '../../steps/PowerOfAttorneyStep'
import { ProductCategory } from 'models/product'
import { PropertyInformationStep } from '../../steps/PropertyInformationStep'
import constate from 'constate'
import { useActionPopup } from 'utils/hooks'
import { useAuth0 } from 'utils/auth'
import { useAuthoritiesDocuments } from './AuthoritiesDocuments.context'
import { useBookCreative } from '../../common'
import { useDynamicFlowController } from 'components/common/DynamicFlow'
import { useFlag } from '@unleash/proxy-client-react'
import { useFloorPlanConfig } from '../../steps/FloorPlanConfig/_main/FloorPlanConfigStep.context'
import { useFloorPlanLogo } from '../../steps/FloorPlanConfig/_main/FloorPlanLogo.context'
import { usePropertyInformationStep } from '../../steps/PropertyInformationStep/PropertyInformationStep.context'
import { usePurchaseFlowConfig } from './PurchaseFlowConfig.context'
import { usePurchaseFlowOrderMeta } from './PurchaseFlowOrderMeta.context'
import { usePurchaseFlowPlaceOrder } from '../../steps/Validation/_main/contexts'
import { usePurchaseFlowProducts } from './PurchaseFlowProducts.context'
import { useRoomAPI } from 'components/contexts/RoomAPI.context'
import { useTargetOrderUser } from './TargetOrderUser.context'
import { useTranslation } from 'react-i18next'
import { useUploadedPurchaseFlowVisuals } from './UploadedPurchaseFlowVisuals.context'

/** Enum of all purchase flow steps */
export enum PurchaseFlowStepKey {
  CLIENT = 'CLIENT',
  CATEGORY = 'CATEGORY',
  ADDRESS = 'ADDRESS',
  PRODUCT = 'PRODUCT',
  STYLE_SECTION = 'STYLE_SECTION',
  INSTRUCTIONS = 'INSTRUCTIONS',
  BILLING = 'BILLING',
  VALIDATION = 'VALIDATION',
  FLOOR_PLAN_CONFIG = 'FLOOR_PLAN_CONFIG',
  PROPERTY_INFORMATION = 'PROPERTY_INFORMATION',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
}

export const [PurchaseFlowUtilityProvider, usePurchaseFlowUtilities] = constate(() => {
  const { roles } = useAuth0()
  const { t } = useTranslation(['purchase_flow'])
  const navigate = useNavigate()
  const { showConfirm } = useActionPopup()
  const flowCtrl = useDynamicFlowController()
  const allowFloorPlanFlow = useFlag(FeatureFlag.ALLOW_FLOOR_PLAN_CONFIG_FLOW)
  const allowAuthoritiesDocs = useFlag(FeatureFlag.ALLOW_AUTHORITIES_DOCUMENTS)
  const allowCompass = useFlag(FeatureFlag.ALLOW_COMPASS_AND_DISCLAIMER)

  const { clientSelectionError } = useTargetOrderUser()
  const { selectedProducts, selectedKinds, selectedProductTypes, hasFloorPlanProduct } = usePurchaseFlowProducts()
  const { uploadedImagesByProduct, uploadInProgress } = useUploadedPurchaseFlowVisuals()
  const { allRoomsCompleted } = useRoomAPI()
  const { selectedCreative } = useBookCreative()
  const { isTemplateLogoUploading } = useFloorPlanLogo()
  const { isPlaceOrderButtonDisabled } = usePurchaseFlowPlaceOrder()
  const {
    showCompass,
    compassAddress,
    selectedPropertyType,
    showDisclaimer,
    disclaimerText
  } = useFloorPlanConfig()
  const {
    selectedCategory,
    selectedAssignmentPlace,
    isInstructionStepSkipped,
  } = usePurchaseFlowConfig()
  const {
    areCurrentMeetingFieldsValid,
    selectedPaymentMethod,
    selectedExtraServices,
    selectedMeetingType,
    billingAddressError,
  } = usePurchaseFlowOrderMeta()
  const {
    landRegisterSheetItems,
    landlordDateOfBirth,
    landlordFirstName,
    landlordLastName,
    isLandlordInfoPartiallyFilled,
  } = usePropertyInformationStep()
  const { searchProduct } = useAuthoritiesDocuments()

  /* -- PF STEPS AND NAVIGATION UTILITIES -- */
  const exitPurchaseFlow = useCallback(async (showConfirmation = false, pathTo: To = Path.INDEX) => {
    if (showConfirmation) {
      const response = await showConfirm(
        t('exit_confirm.text'),
        {
          title: t('exit_confirm.title'),
          confirmAcceptText: t('exit_confirm.accept_button'),
          confirmDenyText: t('exit_confirm.deny_button'),
        }
      )

      if (!response) return

      // Only log analytics when the user confirms exiting the PF.
      logAnalyticsEvent(AnalyticsEvent.CLOSE_PURCHASE_FLOW, { OrderStep: flowCtrl.currentNode?.key })
    }

    navigate(pathTo)
  }, [flowCtrl.currentNode, navigate, showConfirm, t])
  /* -- END OF PF STEPS AND NAVIGATION UTILITIES -- */

  /* -- STEP VALIDATORS -- */
  const isUserStepValid = useMemo(() => {
    if (!roles.isAdmin) return true

    return !clientSelectionError
  }, [clientSelectionError, roles.isAdmin])

  const isCategoryStepValid = useMemo(() => {
    if (!isUserStepValid) return false

    return !!selectedCategory
  }, [isUserStepValid, selectedCategory])

  const isAddressStepValid = useMemo(() => {
    if (!isCategoryStepValid) return false

    // Need to enforce the type, TS is unable to derive it from the check :/
    if (isEditingCategory(selectedCategory as ProductCategory)) return true

    return !!selectedAssignmentPlace && isAddressValid(selectedAssignmentPlace)
  }, [isCategoryStepValid, selectedAssignmentPlace, selectedCategory])

  const isProductStepValid = useMemo(() => {
    if (!isAddressStepValid) return false

    const selectedProductsArray = Object.values(selectedProducts)

    if (selectedProductsArray.length <= 0) return false

    // Shooting products need no extras, return true for them
    if (selectedCategory && isShootingCategory(selectedCategory)) return true

    // Document products need no extras, return true for them
    if (selectedCategory && isDocumentCategory(selectedCategory)) return true

    // Handle image upload validation for editing/staging
    if (uploadInProgress) return false

    // For editing check, that all products requiring an image have uploaded at least 1 image (all except renderings)
    if (selectedCategory === EditingCategory.EDITING) {
      return selectedProductsArray.every((product) => {
        if (NoUploadEditingKinds.has(product.kind)) return true
        if (UnlimitedUploadKinds.has(product.kind) && Object.values(uploadedImagesByProduct[product.id] || {}).length >= product.quantity) return true

        return Object.values(uploadedImagesByProduct[product.id] || {}).length === product.quantity
      })
    }

    // For staging check, that all products have exact number of uploaded images as quantity selected
    if (selectedCategory === EditingCategory.STAGING) {
      return selectedProductsArray.every((product) =>
        product.quantity === Object.keys(uploadedImagesByProduct[product.id] || {}).length
      )
    }

    // Fall-through case should result to false = invalid
    return false
  }, [isAddressStepValid, selectedCategory, selectedProducts, uploadInProgress, uploadedImagesByProduct])

  const isFloorPlanConfigStepValid = useMemo(() => {
    if (isTemplateLogoUploading) return false
    if (!selectedPropertyType) return false
    if (!allowCompass) return true
    if (showCompass && selectedCategory && isEditingCategory(selectedCategory) && (!compassAddress || !isAddressValid(compassAddress))) return false
    if (showDisclaimer && !disclaimerText) return false

    return true
  }, [isTemplateLogoUploading, selectedPropertyType, allowCompass, showCompass, selectedCategory, compassAddress, showDisclaimer, disclaimerText])

  const isStagingStepValid = useMemo(() => {
    if (selectedCategory !== EditingCategory.STAGING) return true
    if (!isProductStepValid) return false

    return allRoomsCompleted
  }, [allRoomsCompleted, isProductStepValid, selectedCategory])

  const isInstructionStepValid = useMemo(() => {
    if (!isStagingStepValid || !selectedCategory) return false

    if (isShootingCategory(selectedCategory) && (!selectedMeetingType || !areCurrentMeetingFieldsValid)) return false
    if (selectedExtraServices.has(ProductKind.FAVOURITE_CREATIVE) && !selectedCreative) return false

    return true
  }, [areCurrentMeetingFieldsValid, isStagingStepValid, selectedCategory, selectedCreative, selectedExtraServices, selectedMeetingType])

  const isBillingStepValid = useMemo(() => {
    if (!isInstructionStepSkipped && !isInstructionStepValid) return false

    if (!selectedPaymentMethod) return false
    if (billingAddressError) return false

    return true
  }, [isInstructionStepValid, selectedPaymentMethod, billingAddressError, isInstructionStepSkipped])

  const isRecapStepValid = useMemo(() => {
    if (!isBillingStepValid) return false

    return !isPlaceOrderButtonDisabled
  }, [isBillingStepValid, isPlaceOrderButtonDisabled])

  const isLastStep = useMemo(() => !!flowCtrl.currentNode && !flowCtrl.currentNode.next, [flowCtrl.currentNode])

  useEffect(() => {
    if (selectedCategory && selectedCategory === EditingCategory.STAGING) {
      if (!flowCtrl.nodes.get(PurchaseFlowStepKey.STYLE_SECTION)) flowCtrl.insertAfter(
        PurchaseFlowStepKey.PRODUCT,
        PurchaseFlowStepKey.STYLE_SECTION,
        {
          component: <StyleSelectionStep />,
          hasFooter: true,
          type: 'secondary',
        }
      )
    } else {
      flowCtrl.removeNode(PurchaseFlowStepKey.STYLE_SECTION)
    }

    if (isInstructionStepSkipped) flowCtrl.removeNode(PurchaseFlowStepKey.INSTRUCTIONS)
    else if (!flowCtrl.nodes.get(PurchaseFlowStepKey.INSTRUCTIONS)) flowCtrl.insertBefore(
      PurchaseFlowStepKey.BILLING,
      PurchaseFlowStepKey.INSTRUCTIONS,
      {
        component: <InstructionStep />,
        hasFooter: true,
        type: 'main',
      }
    )

    // Omit flowCtrl dependency to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInstructionStepSkipped, selectedCategory])

  // Handle FloorPlanConfigStep add/remove
  useEffect(() => {
    if (!allowFloorPlanFlow) return

    if ((hasFloorPlanProduct) && !flowCtrl.nodes.get(PurchaseFlowStepKey.FLOOR_PLAN_CONFIG)) {
      flowCtrl.insertAfter(
        PurchaseFlowStepKey.PRODUCT,
        PurchaseFlowStepKey.FLOOR_PLAN_CONFIG,
        {
          component: <FloorPlanConfigStep />,
          hasFooter: true,
          type: 'secondary',
        }
      )
    } else if (!hasFloorPlanProduct) flowCtrl.removeNode(PurchaseFlowStepKey.FLOOR_PLAN_CONFIG)

    // Omit flowCtrl to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKinds, selectedProductTypes])

  // Handle Document steps add/remove
  useEffect(() => {
    if (!allowAuthoritiesDocs) return

    if (selectedCategory && selectedCategory === DocumentCategory.AUTHORITIES_DOCUMENTS) {
      if (!flowCtrl.nodes.get(PurchaseFlowStepKey.POWER_OF_ATTORNEY)) flowCtrl.insertAfter(
        PurchaseFlowStepKey.PRODUCT,
        PurchaseFlowStepKey.POWER_OF_ATTORNEY,
        {
          component: <PowerOfAttorneyStep />,
          hasFooter: true,
          type: 'secondary',
        }
      )
    } else {
      flowCtrl.removeNode(PurchaseFlowStepKey.POWER_OF_ATTORNEY)
    }

    const isLandRegistrationRequired = Object.values(selectedProducts).some((product) => product.isLandRegistrationNeeded)

    if (isLandRegistrationRequired && !flowCtrl.nodes.get(PurchaseFlowStepKey.PROPERTY_INFORMATION)) {
      flowCtrl.insertBefore(
        PurchaseFlowStepKey.POWER_OF_ATTORNEY,
        PurchaseFlowStepKey.PROPERTY_INFORMATION,
        {
          component: <PropertyInformationStep />,
          hasFooter: true,
          type: 'secondary',
        }
      )
    } else if (!isLandRegistrationRequired) {
      flowCtrl.removeNode(PurchaseFlowStepKey.PROPERTY_INFORMATION)
    }

    // Omit flowCtrl to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowAuthoritiesDocs, selectedCategory, selectedKinds, selectedProductTypes, selectedProducts])

  const isPropertyInformationStepValid = useMemo(() => {
    if (!isProductStepValid) return false
    if (!landRegisterSheetItems) return false

    if (!searchProduct || !selectedProducts[searchProduct.id]) {
      for (const [, value] of landRegisterSheetItems.entries()) {
        if (!value.name || !value.district || !value.floor.every((floor) => !!floor.hallway)) {
          return false
        }
      }
    }

    if (isLandlordInfoPartiallyFilled &&
      (!landlordFirstName || !landlordLastName || !landlordDateOfBirth)
    ) return false

    return true
  }, [isLandlordInfoPartiallyFilled, isProductStepValid, landRegisterSheetItems, landlordDateOfBirth, landlordFirstName, landlordLastName, searchProduct, selectedProducts])

  return {
    isLastStep,
    isUserStepValid,
    isCategoryStepValid,
    isAddressStepValid,
    isProductStepValid,
    isStagingStepValid,
    isFloorPlanConfigStepValid,
    isInstructionStepValid,
    isBillingStepValid,
    isRecapStepValid,
    isInstructionStepSkipped,
    isPropertyInformationStepValid,
    exitPurchaseFlow,
  }
})
