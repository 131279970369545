import { EntityKeys, QueryType, getMutation } from 'utils/reactQuery'

import { useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { ESProduct } from 'constants/product'
import { VisualFileType } from 'constants/visual'
import { FeeDTO } from 'models/fee'
import { StatusResponse } from 'models/redux'
import { AdditionalVisualPriceDTO } from 'models/visuals'
import { useAPI } from 'utils/API'

enum Endpoints {
  VISUAL_FAVORITE = '/assignment/{assignmentId}/visual/favorite',
  ADMIN_SEND_ASSIGNMENT_VISUALS_TO_CLIENT = '/assignment/{assignmentId}/deliver',
  ADMIN_SEND_ASSIGNMENT_VISUALS_TO_EDITOR = '/assignment/{assignmentId}/visual/edit',
  VISUAL_ADDITIONAL_PRICE = '/assignment/{assignmentId}/additionalVisualPrice',
  VISUAL_GET_VIRTUAL_VISIT_EXTENSION_PRICE = '/assignment/{assignmentId}/virtualVisit/extendExpiration/price',
}

interface VirtualVisitExtensionPriceDTO {
  fee: FeeDTO
  productId: number
}

// QUERIES

export function useGetAdditionalVisualPrice(assignmentId: string) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ASSIGNMENT_VISUAL_PRICE, QueryType.GET_ONE, { assignmentId }],
    queryFn: () => api.get<AdditionalVisualPriceDTO>(
      Endpoints.VISUAL_ADDITIONAL_PRICE,
      { assignmentId },
      true
    ),
  })
}

export function useGetVirtualVisitExtensionPrice(assignmentId: string) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.VIRTUAL_VISIT_EXTENSION_PRICE, QueryType.GET_ONE, { assignmentId }],
    queryFn: () => api.get<VirtualVisitExtensionPriceDTO>(
      Endpoints.VISUAL_GET_VIRTUAL_VISIT_EXTENSION_PRICE,
      { assignmentId },
      true
    ),
  })
}

// MUTATIONS

export interface AdminSendVisualsToEditorPayload {
  assignmentId: string,
  filenames: string[],
  product: ESProduct,
  comment: string,
}

/** Mutation for adding favorite visual */
export function useAddFavoriteVisual() {
  const api = useAPI<Endpoints>()

  return getMutation<{}, { assignmentId: string, visuals: string[], }>(
    ({ assignmentId, visuals }) => api.post(
      Endpoints.VISUAL_FAVORITE,
      {
        assignmentId: assignmentId.toString(),
      },
      {
        visuals
      },
      false
    )
  )
}

/** Mutation for removing favorite visual */
export function useDeleteFavoriteVisual() {
  const api = useAPI<Endpoints>()

  return getMutation<{}, { assignmentId: string, visuals: string[] }>(
    ({ assignmentId, visuals }) => api.delete(
      Endpoints.VISUAL_FAVORITE,
      {
        assignmentId: assignmentId.toString(),
      },
      false,
      {
        data: {
          visuals
        }
      }
    )
  )
}

/** Mutation for send assignment visuals to client */
export function useSendVisualsToClient() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, { assignmentId: string }>(
    ({ assignmentId }) => api.post(
      Endpoints.ADMIN_SEND_ASSIGNMENT_VISUALS_TO_CLIENT,
      {
        assignmentId,
      },
      {},
      false
    )
  )
}

/** Mutation for send assignment visuals to editor */
export function useSendVisualsToEditor() {
  const api = useAPI<Endpoints>()

  return getMutation<AxiosResponse<StatusResponse>, AdminSendVisualsToEditorPayload>(
    ({ assignmentId, filenames, product, comment }) => api.post(
      Endpoints.ADMIN_SEND_ASSIGNMENT_VISUALS_TO_EDITOR,
      {
        assignmentId,
      },
      {
        filenames,
        product,
        comment,
        visualType: VisualFileType.RAW
      },
      false
    )
  )
}
