import { ImmutableList, ImmutableMap, Nullable } from 'models/helpers'
import { useEffect, useMemo, useState } from 'react'

import { Moment } from 'moment-timezone'
import constate from 'constate'
import { uniqueId } from 'lodash'

/**
 * Represents an item in the land register sheet floor.
 */
type LandRegisterSheetFloorItem = {
  /** The hallway of the floor. */
  hallway: string
  /** The parcel of the floor. */
  parcel: string
}

/**
 * Represents an item in the land register sheet.
 */
type LandRegisterSheetItem = {
  /** The name of land register sheet item. */
  name: string
  /** The district of the item. */
  district: string
  /** The list of floors in the item. */
  floor: ImmutableList<LandRegisterSheetFloorItem>
}

/**
 * Generates a unique ID for a land register sheet.
 * @returns The generated unique ID.
 */
const _generateLandRegisterSheetId = (): string => uniqueId('land-register-')

/**
 * Generates a default land register sheet item.
 * @returns The generated default land register sheet item.
 */
const _generateDefaultLandRegisterSheetItem = (): LandRegisterSheetItem => {
  return {
    name: '',
    district: '',
    floor: ImmutableList([
      {
        hallway: '',
        parcel: ''
      }
    ])
  }
}

export const [PropertyInformationStepContextProvider, usePropertyInformationStep] = constate(() => {
  const [landRegisterSheetItems, setLandRegisterSheetItems] = useState<ImmutableMap<string, LandRegisterSheetItem>>(ImmutableMap([]))
  const [landlordFirstName, setLandlordFirstName] = useState<string>('')
  const [landlordLastName, setLandlordLastName] = useState<string>('')
  const [landlordDateOfBirth, setLandlordDateOfBirth] = useState<Nullable<Moment>>(null)

  /**
   * Updates the land register sheet item with the specified ID.
   * 
   * @param id - The ID of the land register sheet item to update.
   * @param updates - The partial updates to apply to the land register sheet item.
   */
  const updateSheetList = (id: string, updates: Partial<LandRegisterSheetItem>) => {
    if (!landRegisterSheetItems.get(id)) return

    setLandRegisterSheetItems((prevItems) => prevItems.update(id, item => ({ ...item!, ...updates })))
  }

  /**
   * Updates the land register sheet floor with the specified id and floor index.
   * 
   * @param id - The id of the land register sheet.
   * @param floorIndex - The index of the floor to update.
   * @param updates - The partial updates to apply to the floor.
   */
  const updateLandRegisterSheetFloor = (id: string, floorIndex: number, updates: Partial<LandRegisterSheetFloorItem>) => {

    if (!landRegisterSheetItems.get(id)?.floor.get(floorIndex)) return

    updateSheetList(
      id,
      {
        floor: landRegisterSheetItems.get(id)!.floor.update(floorIndex, prev => ({ ...prev!, ...updates }))
      }
    )
  }

  /**
   * Adds a new land register sheet.
   */
  const onAddLandRegisterSheet = () => {
    setLandRegisterSheetItems(prev => prev.set(_generateLandRegisterSheetId(), _generateDefaultLandRegisterSheetItem()))
  }

  /**
   * Removes a land register sheet.
   * @param id - The ID of the land register sheet.
   */
  const onRemoveLandRegisterSheet = (id: string) => {
    setLandRegisterSheetItems(prev => prev.remove(id))
  }

  /**
   * Adds a new floor to a land register sheet.
   * @param id - The ID of the land register sheet.
   */
  const addFloor = (id: string) => {
    if (!landRegisterSheetItems.get(id)) return

    const newFloor = {
      hallway: '',
      parcel: '',
    }

    updateSheetList(id, { floor: landRegisterSheetItems.get(id)!.floor.push(newFloor) })
  }

  /**
   * Removes a floor from a land register sheet.
   * @param id - The ID of the land register sheet.
   * @param index - The index of the floor to remove.
   */
  const removeFloor = (id: string, index: number) => {
    if (!landRegisterSheetItems.get(id)) return

    updateSheetList(id, { floor: landRegisterSheetItems.get(id)!.floor.splice(index, 1) })
  }

  /**
   * Indicates whether the landlord information is partially filled.
   */
  const isLandlordInfoPartiallyFilled: boolean = useMemo(() => {
    return !!(landlordFirstName || landlordLastName || landlordDateOfBirth)
  }, [landlordDateOfBirth, landlordFirstName, landlordLastName])

  useEffect(() => {
    // Set default land register sheet items
    if (!landRegisterSheetItems.size) {
      setLandRegisterSheetItems(prev => prev.set(_generateLandRegisterSheetId(), _generateDefaultLandRegisterSheetItem()))
    }
    // Only run on first render, no need to add dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    landRegisterSheetItems,
    isLandlordInfoPartiallyFilled,
    landlordFirstName,
    landlordLastName,
    landlordDateOfBirth,
    addFloor,
    removeFloor,
    onAddLandRegisterSheet,
    onRemoveLandRegisterSheet,
    setLandlordFirstName,
    setLandlordLastName,
    setLandlordDateOfBirth,
    updateSheetList,
    updateLandRegisterSheetFloor,
  }
})
