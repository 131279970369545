/** An Enumeration of all available colors */
export enum Color {
  WHITE = '#FFFFFF',
  BLACK = '#000000',
  PRIMARY_BLUE = '#EDF7FD',
  PRIMARY_ORANGE = '#FFF4F0',
  PRIMARY_GREEN = '#F4F9F4',
  PRIMARY_RED = '#FFF9F9',

  // secondary
  SECONDARY_BLUE_LIGHT = '#BBE1F7',
  SECONDARY_BLUE_DARK = '#48ADEA',
  SECONDARY_ORANGE_LIGHT = '#FADCC6',
  SECONDARY_ORANGE_DARK = '#FDA54F',
  SECONDARY_GREEN_LIGHT = '#D5EDD5',
  SECONDARY_GREEN_DARK = '#77CD77',
  SECONDARY_RED_LIGHT = '#FFE8E8',
  SECONDARY_RED_DARK = '#F96060',

  // Gray
  GRAY_BACKGROUND = '#FAFAFA',
  GRAY_LIGHT_BACKGROUND = '#FDFDFD',
  GRAY_BORDER = '#EDEDED',
  GRAY_SECONDARY = '#C2C2C2',
  GRAY_LIGHT_TEXT = '#DADADA',
  GRAY_TEXT = '#6E6E6E',
  GRAY_DISABLED = '#B3B3B3',
}
