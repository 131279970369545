import { Dispatch, FC, ReactNode, RefObject, SetStateAction, createContext, createRef, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { TypeOfAvailableVirtualVisitLinkTypes, useGalleryConstants } from './GalleryConstants.context'

import { APIRequestState } from 'constants/API'
import moment from 'moment-timezone'
import { useAuth0 } from 'utils/auth'
import { useGalleryVisualsMeta } from './GalleryVisualsMeta.context'

interface GalleryVirtualVisitInterface {
  virtualVisitShareLinkRef: RefObject<HTMLInputElement>
  virtualVisitEmbedCodeRef: RefObject<HTMLInputElement>
  selectedVirtualVisitLinkType: TypeOfAvailableVirtualVisitLinkTypes
  setSelectedVirtualVisitLinkType: Dispatch<SetStateAction<TypeOfAvailableVirtualVisitLinkTypes>>
  virtualVisitInput: string
  setVirtualVisitInput: Dispatch<SetStateAction<string>>
  virtualVisitID: string
  setVirtualVisitID: Dispatch<SetStateAction<string>>
  generateVirtualVisitShareLink: (VVID: string, linkType: TypeOfAvailableVirtualVisitLinkTypes) => string
  virtualVisitShareLink: string
  virtualVisitExpirationDate: string | null
  virtualVisitExpired: boolean
  virtualVisitMatterportEmail?: string
  virtualVisitEmbedCode: string
}

const defaultGalleryVirtualVisitValue: GalleryVirtualVisitInterface = {
  virtualVisitShareLinkRef: createRef(),
  virtualVisitEmbedCodeRef: createRef(),
  selectedVirtualVisitLinkType: 'BRANDED',
  setSelectedVirtualVisitLinkType: () => { throw new Error('setSelectedVirtualVisitLinkType is undefined') },
  virtualVisitInput: '',
  setVirtualVisitInput: () => { throw new Error('setVirtualVisitInput is undefined') },
  virtualVisitID: '',
  setVirtualVisitID: () => { throw new Error('setVirtualVisitID is undefined') },
  generateVirtualVisitShareLink: () => { throw new Error('generateVirtualVisitShareLink is undefined') },
  virtualVisitShareLink: '',
  virtualVisitExpirationDate: null,
  virtualVisitExpired: false,
  virtualVisitEmbedCode: '',
}

/** Gallery virtual visit context */
export const GalleryVirtualVisitContext = createContext<GalleryVirtualVisitInterface>(defaultGalleryVirtualVisitValue)
/** Gallery virtual visit context hook */
export const useGalleryVirtualVisit = (): GalleryVirtualVisitInterface => useContext(GalleryVirtualVisitContext)

/** Context provider for gallery virtual visit */
export const GalleryVirtualVisitContextProvider: FC<{
  assignmentId: string
  children?: ReactNode
}> = ({
  assignmentId,
  children,
}) => {

    const { roles } = useAuth0()

    const { AvailableVirtualVisitLinkTypes } = useGalleryConstants()
    const { allVisuals } = useGalleryVisualsMeta()

    const virtualVisitShareLinkRef = useRef<HTMLInputElement>(null)
    const virtualVisitEmbedCodeRef = useRef<HTMLInputElement>(null)
    const [selectedVirtualVisitLinkType, setSelectedVirtualVisitLinkType] = useState<TypeOfAvailableVirtualVisitLinkTypes>(AvailableVirtualVisitLinkTypes.BRANDED)
    const [virtualVisitInput, setVirtualVisitInput] = useState('')
    const [virtualVisitID, setVirtualVisitID] = useState('')
    const generateVirtualVisitShareLink = useCallback((VVID: string, linkType: TypeOfAvailableVirtualVisitLinkTypes) => {
      if (!VVID) return ''
      // Use this link to learn about Matterport params https://support.matterport.com/s/article/URL-Parameters?language=en_US
      const root = `https://my.matterport.com/show/?m=${VVID}`
      switch (linkType) {
        case AvailableVirtualVisitLinkTypes.UNBRANDED:
          return `${root}&brand=0`
        case AvailableVirtualVisitLinkTypes.MLS_READY:
          return `${root}&mls=${roles.isClient ? '2' : '1'}`
        default:
          return root
      }
    }, [AvailableVirtualVisitLinkTypes, roles])
    const virtualVisitShareLink = useMemo(() => generateVirtualVisitShareLink(virtualVisitID, selectedVirtualVisitLinkType), [generateVirtualVisitShareLink, virtualVisitID, selectedVirtualVisitLinkType])
    const virtualVisitExpirationDate = useMemo(() => allVisuals?.data?.virtualVisit?.expirationDate || null, [allVisuals])
    const virtualVisitExpired = useMemo(() => allVisuals?.data?.virtualVisit?.expirationDate ? moment(allVisuals.data.virtualVisit.expirationDate).isBefore(moment()) : false, [allVisuals])
    const virtualVisitMatterportEmail = useMemo(() => allVisuals?.data?.virtualVisit?.matterportEmail, [allVisuals])
    const virtualVisitEmbedCode = useMemo(() => `<iframe width="853" height="480" src="${virtualVisitShareLink}" frameBorder="0" allowFullScreen allow="xr-spatial-tracking"></iframe>`, [virtualVisitShareLink])

    // Update virtual visit ID and input when visual list changes
    useEffect(() => {
      if (!allVisuals) return
      if (allVisuals.state !== APIRequestState.OK) return
      if (!allVisuals.data) return
      setVirtualVisitInput(allVisuals.data.virtualVisit?.matterportId || '')
      setVirtualVisitID(allVisuals.data.virtualVisit?.matterportId || '')
    }, [allVisuals])

    return (
      <GalleryVirtualVisitContext.Provider
        value={{
          virtualVisitShareLinkRef,
          virtualVisitEmbedCodeRef,
          selectedVirtualVisitLinkType,
          setSelectedVirtualVisitLinkType,
          virtualVisitInput,
          setVirtualVisitInput,
          virtualVisitID,
          setVirtualVisitID,
          generateVirtualVisitShareLink,
          virtualVisitShareLink,
          virtualVisitExpirationDate,
          virtualVisitExpired,
          virtualVisitMatterportEmail,
          virtualVisitEmbedCode,
        }}
      >
        {children}
      </GalleryVirtualVisitContext.Provider>
    )
  }
