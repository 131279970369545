import { BEIGE_300, BEIGE_500, GRAY_700, GRAY_900, MOBILE_VIEW_QUERY, SangBleuFontFamily } from 'constants/styling/theme'
import { Box, Stack, Typography, useMediaQuery } from '@mui/material'
import { DropdownInputTypes, MUIDropdownInput } from 'components/common/MUIDropdownInput/MUIDropdownInput.component'
import { FC, ReactNode, useCallback } from 'react'
import { WorkspaceType, useSignupRegistration } from '../SignupRegistration.context'

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded'
import BusinessIcon from '@mui/icons-material/Business'
import { Country } from 'constants/country'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDropdown } from 'components/common/MUIDropdown'
import { MUIDropdownItem } from 'components/common/MUIDropdownItem'
import { MUIInputField } from 'components/common/MUIInputField'
import { OwnerFilterTab } from 'components/common/OwnerFilterTab'
import PersonOutlineIcon from '@mui/icons-material/PersonOutline'
import { PhoneInput } from 'components/common/PhoneInput'
import { QueryStatus } from 'components/common/QueryStatus'
import { useDynamicFlowController } from 'components/common/DynamicFlow'
import { useTranslation } from 'react-i18next'

const CustomPhoneInput: FC<{ children: ReactNode }> = ({ children }) => (
  <Box width="100%" sx={{
    'input': {
      height: '4rem',
      width: '100%',
      borderRadius: '.8rem',
      border: 'unset !important',
    },
    '.iti': {
      display: 'block',
    }
  }}>
    {children}
  </Box>
)

export const SignupCreateWorkspace: React.FC = () => {
  const { t } = useTranslation(['signup', 'country', 'language', 'common'])
  const isMobileView = useMediaQuery(MOBILE_VIEW_QUERY)
  const flowCtrl = useDynamicFlowController()

  const {
    workspaceType,
    setWorkspaceType,
    organizationName,
    setOrganizationName,
    organizationAddress,
    setOrganizationAddress,
    organizationCity,
    setOrganizationCity,
    organizationZIP,
    setOrganizationZIP,
    VATNumber,
    setVATNumber,
    organizationCountry,
    setOrganizationCountry,
    isCreateWorkspaceStepValid,
    country,
    organizationPhone,
    setOrganizationPhone,
    isOrganizationPhoneValid,
    setIsOrganizationPhoneValid,
    personalPhone,
    setPersonalPhone,
    isPersonalPhoneValid,
    setIsPersonalPhoneValid,
    organizationPhoneErrorMsg,
    setOrganizationPhoneErrorMsg,
    personalPhoneErrorMsg,
    setPersonalPhoneErrorMsg,
    maxVATNumberCharacters,
    isMaxVATNumberCharacters,
    submitCreateUser,
    createUser,
  } = useSignupRegistration()

  const onBackClick = useCallback(() => {
    const currentStep = flowCtrl.currentNode

    if (!currentStep?.prev) return

    flowCtrl.setHead(currentStep.prev)

    // Omit flowCtrl dependency to prevent loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowCtrl.currentNode?.prev, flowCtrl.head])

  return (
    <Stack padding={isMobileView ? '8rem 0' : '8rem'} width="36rem">

      <Stack gap="1.6rem" alignItems="center" marginBottom="4rem">
        <Typography variant="h6" color={GRAY_900} fontWeight={500} fontFamily={SangBleuFontFamily}>{t('your_workspace')}</Typography>
        <Typography variant="text-sm" color={GRAY_700}>{t('select_workspace_kind')}</Typography>
      </Stack>

      <Stack flexDirection="row" padding=".4rem" borderRadius=".8rem" marginBottom="2.4rem" sx={{ background: BEIGE_500 }}>
        <OwnerFilterTab
          active={workspaceType === WorkspaceType.BUSINESS}
          icon={<BusinessIcon fontSize='inherit' />}
          text={t('business_account')}
          onClick={() => {
            if (!isOrganizationPhoneValid) setOrganizationPhone('')
            setWorkspaceType(WorkspaceType.BUSINESS)
          }}
        />
        <OwnerFilterTab
          active={workspaceType === WorkspaceType.PERSONAL}
          icon={<PersonOutlineIcon fontSize='inherit' />}
          text={t('personal_account')}
          onClick={() => {
            if (!isPersonalPhoneValid) setPersonalPhone('')
            setWorkspaceType(WorkspaceType.PERSONAL)
          }}
        />
      </Stack>

      {workspaceType === WorkspaceType.BUSINESS &&
        <Stack gap="1.6rem" marginBottom="4rem">

          <MUIInputField
            label={t('organization_name')}
            value={organizationName}
            onChange={e => setOrganizationName(e.target.value)}
          />

          <MUIInputField
            label={t('organization_address')}
            value={organizationAddress}
            onChange={e => setOrganizationAddress(e.target.value)}
          />

          <Stack gap=".8rem" flexDirection="row" justifyContent="space-between">
            <MUIInputField
              label={t('organization_city')}
              value={organizationCity}
              onChange={e => setOrganizationCity(e.target.value)}
            />

            <MUIInputField
              label={t('organization_zip')}
              value={organizationZIP}
              onChange={e => setOrganizationZIP(e.target.value)}
            />
          </Stack>

          <MUIDropdown
            button={(isOpen, action) => (
              <MUIDropdownInput
                readOnly
                label={t('country')}
                type={DropdownInputTypes.SELECTOR}
                value={organizationCountry ? t(`country:${organizationCountry}`) : organizationCountry}
                onClick={action}
              />
            )}
          >
            <Box height="30rem">
              {Object.values(Country)?.map(countryKey => (
                <MUIDropdownItem key={countryKey} onClick={() => setOrganizationCountry(countryKey)}>
                  <Typography variant="text-md" color={GRAY_900} fontWeight="medium">
                    {t(`country:${countryKey}`, countryKey)}
                  </Typography>
                </MUIDropdownItem>
              ))}
            </Box>
          </MUIDropdown>

          <MUIInputField
            label={t('vat_number')}
            value={VATNumber}
            isError={isMaxVATNumberCharacters}
            errorText={t('vat_number_characters_length_message', { count: maxVATNumberCharacters })}
            onChange={e => setVATNumber(e.target.value.replace(/(\r\n|\n|\r)/gm, '').slice(0, maxVATNumberCharacters))}
          />

          <MUIInputField
            label={t('business_phone')}
            errorText={organizationPhoneErrorMsg}
            isError={!isOrganizationPhoneValid}
            customInput={
              <CustomPhoneInput>
                <PhoneInput
                  value={organizationPhone}
                  onChange={(value: string) => setOrganizationPhone(value)}
                  onValidityChange={(isValid, errorMessage) => {
                    setOrganizationPhoneErrorMsg(isValid ? '' : errorMessage)
                    setIsOrganizationPhoneValid(isValid)
                  }}
                  initialCountry={country || Country.CH}
                />
              </CustomPhoneInput>
            }
          />

        </Stack>
      }

      {workspaceType === WorkspaceType.PERSONAL &&
        <Stack gap="1.6rem" marginBottom="4rem">
          <MUIInputField
            label={t('mobile_phone')}
            errorText={personalPhoneErrorMsg}
            isError={!isPersonalPhoneValid}
            customInput={
              <CustomPhoneInput>
                <PhoneInput
                  value={personalPhone}
                  onChange={(value: string) => setPersonalPhone(value)}
                  onValidityChange={(isValid, errorMessage) => {
                    setPersonalPhoneErrorMsg(isValid ? '' : errorMessage)
                    setIsPersonalPhoneValid(isValid)
                  }}
                  initialCountry={country || Country.CH}
                />
              </CustomPhoneInput>
            }
          />
        </Stack>
      }

      <QueryStatus
        query={createUser}
        showStates={['error', 'success']}
        spaceBottomRem={2}
      />

      {!isMobileView &&
        <Stack alignItems="center" justifyContent="flex-end" flexDirection="row" gap="1rem">
          <MUIButton
            size="lg"
            variant="text"
            type="secondaryNoBorder"
            onClick={onBackClick}
          >
            {t('back')}
          </MUIButton>

          <MUIButton
            size="lg"
            endIcon={<ArrowForwardRoundedIcon fontSize="large" />}
            disabled={!isCreateWorkspaceStepValid}
            isLoading={createUser.isPending}
            onClick={submitCreateUser}
          >
            {t('discover_bkbn')}
          </MUIButton>
        </Stack>
      }

      {isMobileView &&
        <Stack
          gap=".4rem"
          alignItems="center"
          justifyContent="flex-end"
          padding="2.4rem 1.6rem"
          flexDirection="row"
          position="fixed"
          bottom="0"
          left="0"
          width="100%"
          boxSizing="border-box"
          zIndex={1}
          sx={{ background: BEIGE_300 }}
        >
          <MUIButton
            size="lg"
            variant="text"
            type="secondaryNoBorder"
            onClick={onBackClick}
          >
            {t('back')}
          </MUIButton>

          <MUIButton
            fullWidth
            size="lg"
            endIcon={<ArrowForwardRoundedIcon fontSize="large" />}
            disabled={!isCreateWorkspaceStepValid}
            isLoading={createUser.isPending}
            onClick={submitCreateUser}
          >
            {t('discover_bkbn')}
          </MUIButton>
        </Stack>
      }

    </Stack>
  )
}
