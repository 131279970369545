import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react'

import { APIRequestState } from 'constants/API'
import { ActionTypeAPIData } from 'constants/redux'
import { Country } from 'constants/country'
import { Language } from 'translations/Language'
import { RootStore } from 'models/redux'
import { SignupHowDidYouHearAnswers } from 'constants/signupQuestions'
import constate from 'constate'
import { getUserTimezone } from 'utils/time'
import i18n from 'translations/i18n'
import { logAnalyticsEvent } from 'utils/analytics'
import { useAuth0 } from 'utils/auth'
import { useCreateUser } from 'dataQueries'
import { useSelector } from 'react-redux'

export enum WorkspaceType {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL',
}

export const [SignupRegistrationProvider, useSignupRegistration] = constate(() => {
  const { refreshUser } = useAuth0()

  const userMe = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.USER_ME])
  const userMetaRequest = useSelector((state: RootStore) => state.APIData[ActionTypeAPIData.USER_META])

  const i18nLang = useMemo(() => i18n.language.toUpperCase().replace(/-.*/igm, ''), [])

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [country, setCountry] = useState<Country>(Country.CH)
  const [organizationPhone, setOrganizationPhone] = useState('')
  const [isOrganizationPhoneValid, setIsOrganizationPhoneValid] = useState(false)
  const [organizationPhoneErrorMsg, setOrganizationPhoneErrorMsg] = useState<string>()
  const [personalPhone, setPersonalPhone] = useState('')
  const [isPersonalPhoneValid, setIsPersonalPhoneValid] = useState(false)
  const [personalPhoneErrorMsg, setPersonalPhoneErrorMsg] = useState<string>()
  const [timezone, setTimezone] = useState(getUserTimezone)
  const [language, setLanguage] = useState<Language>(Language[i18nLang as Language] ? Language[i18nLang as Language] : Language.EN)
  const [organizationName, setOrganizationName] = useState('')
  const [organizationAddress, setOrganizationAddress] = useState('')
  const [organizationCity, setOrganizationCity] = useState('')
  const [organizationZIP, setOrganizationZIP] = useState('')
  const [organizationCountry, setOrganizationCountry] = useState<Country>(Country.CH)
  const [VATNumber, setVATNumber] = useState('')
  const [howDidYouHear, setHowDidYouHear] = useState('')
  const [workspaceType, setWorkspaceType] = useState<WorkspaceType>(WorkspaceType.BUSINESS)

  const createUser = useCreateUser()
  const isCreateUserProcessed = useMemo(() => createUser.isPending || createUser.isSuccess, [createUser.isPending, createUser.isSuccess])

  const maxVATNumberCharacters = 50
  const isMaxVATNumberCharacters = useMemo(() => VATNumber.length === maxVATNumberCharacters, [VATNumber])

  const isAccountInfoStepValid = useMemo(() => {
    if (!firstName || !lastName) return false
    if (!country) return false
    if (!language) return false
    if (!timezone) return false
    if (!howDidYouHear) return false

    return true
  }, [country, firstName, howDidYouHear, language, lastName, timezone])

  const isCreateWorkspaceStepValid = useMemo(() => {
    if (workspaceType === WorkspaceType.BUSINESS) {
      if (!organizationPhone || !isOrganizationPhoneValid) return false
      if (!organizationName) return false
      if (!organizationAddress || !organizationCity || !organizationCountry || !organizationZIP) return false
    }

    if (workspaceType === WorkspaceType.PERSONAL) {
      if (!personalPhone || !isPersonalPhoneValid) return false
    }

    if (isCreateUserProcessed) return false

    return true
  }, [isCreateUserProcessed, isOrganizationPhoneValid, isPersonalPhoneValid, organizationAddress, organizationCity, organizationCountry, organizationName, organizationPhone, organizationZIP, personalPhone, workspaceType])

  /** Create user action */
  const submitCreateUser = useCallback(() => {
    const onboardingAnswerKey = Object.keys(SignupHowDidYouHearAnswers).find(
      (key) => SignupHowDidYouHearAnswers[key as keyof typeof SignupHowDidYouHearAnswers] === howDidYouHear
    ) as keyof typeof SignupHowDidYouHearAnswers | undefined

    const isBusinessWorkspace = workspaceType === WorkspaceType.BUSINESS
    const isPersonalWorkspace = workspaceType === WorkspaceType.PERSONAL

    createUser.mutate({
      name: `${firstName} ${lastName}`,
      firstName,
      lastName,
      country,
      phone: isBusinessWorkspace ? organizationPhone : '',
      mobilePhone: isPersonalWorkspace ? personalPhone : '',
      locale: {
        language,
        timezone,
      },
      hasOrganization: isBusinessWorkspace,
      organization: isBusinessWorkspace ? {
        name: organizationName,
        billingAddress: `${organizationAddress}, ${organizationZIP} ${organizationCity}, ${organizationCountry}`,
        hasB2CInvoicing: false,
      } : null,
      isOrganizationCorrect: false,
      vatNumber: VATNumber || undefined,
      onboardingAnswer: onboardingAnswerKey || undefined,
      refreshUserCallback: refreshUser
    })
  }, [VATNumber, country, createUser, firstName, howDidYouHear, language, lastName, organizationAddress, organizationCity, organizationCountry, organizationName, organizationPhone, organizationZIP, personalPhone, refreshUser, timezone, workspaceType])

  /** log entering this page */
  useEffect(() => {
    logAnalyticsEvent('enters_user_signup_details_screen', {})
  }, [])

  /** React to userMe request and set default timezone */
  useEffect(() => {
    if (userMe.data) setTimezone(getUserTimezone(userMe.data))
  }, [userMe])

  /** Cleanup create user request */
  useLayoutEffect(() => {
    createUser.reset()
    return () => {
      createUser.reset()
    }
    // No dependencies needed to avoid loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /** Prefill info from metadata */
  useLayoutEffect(() => {
    if (!userMetaRequest.data) return
    if (userMetaRequest.state !== APIRequestState.OK) return
    if (userMetaRequest.data.organization?.name) setOrganizationName(userMetaRequest.data.organization.name)
    if (userMetaRequest.data.organization?.billingAddress) setOrganizationAddress(userMetaRequest.data.organization.billingAddress)
    const { country, name } = userMetaRequest.data
    const fullName = name.split(' ')
    if (name?.[0]) setFirstName(fullName[0])
    if (name?.[1]) setLastName(fullName.slice(1).join(' '))
    if (country && Country[country as Country]) setCountry(country as Country)
  }, [userMetaRequest])

  return {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    country,
    setCountry,
    organizationPhone,
    setOrganizationPhone,
    isOrganizationPhoneValid,
    setIsOrganizationPhoneValid,
    personalPhone,
    setPersonalPhone,
    isPersonalPhoneValid,
    setIsPersonalPhoneValid,
    timezone,
    setTimezone,
    language,
    setLanguage,
    organizationName,
    setOrganizationName,
    organizationAddress,
    setOrganizationAddress,
    organizationCity,
    setOrganizationCity,
    organizationZIP,
    setOrganizationZIP,
    organizationCountry,
    setOrganizationCountry,
    VATNumber,
    setVATNumber,
    howDidYouHear,
    setHowDidYouHear,
    workspaceType,
    setWorkspaceType,
    organizationPhoneErrorMsg,
    setOrganizationPhoneErrorMsg,
    personalPhoneErrorMsg,
    setPersonalPhoneErrorMsg,
    createUser,
    maxVATNumberCharacters,
    isMaxVATNumberCharacters,
    isAccountInfoStepValid,
    isCreateWorkspaceStepValid,
    submitCreateUser,
  }
})
