
/** Enumeration of redux action types */
export enum ActionStringType {
  CHANGE_LANGUAGE = 'CHANGE_LANGUAGE',
  OPEN_ACTION_POPUP = 'OPEN_ACTION_POPUP',
  CLOSE_ACTION_POPUP = 'CLOSE_ACTION_POPUP',
}

/** Enumeration of redux action api event types */
export enum ActionTypeAPIEvent {
  BEFORE_START = 'BEFORE_START',
  FETCH = 'FETCH',
  RECEIVED = 'RECEIVED',
  PROGRESS = 'PROGRESS',
}

/** Enumeration of redux action data types */
export enum ActionTypeAPIData {
  // USER
  USER_ME = 'USER_ME',
  UPDATE_USER_ME_STORE_DATA = 'UPDATE_USER_ME_STORE_DATA',
  UPDATE_USER_ME = 'UPDATE_USER_ME',
  USER_ME_ALTERNATIVE = 'USER_ME_ALTERNATIVE',
  USER_META = 'USER_META',
  USER_CHANGE_LOCALE = 'USER_CHANGE_LOCALE',
  UPDATE_VAT_NUMBER = 'UPDATE_VAT_NUMBER',
  UPDATE_RECEIPT_EMAIL = 'UPDATE_RECEIPT_EMAIL',
  UPDATE_PREFERRED_PAYMENT_METHOD = 'UPDATE_PREFERRED_PAYMENT_METHOD',
  USER_GET_WORKSPACES = 'USER_GET_WORKSPACES',

  // ORGANIZATION
  ORGANIZATION_ME = 'ORGANIZATION_ME',
  SEARCH_ORGANIZATIONS = 'SEARCH_ORGANIZATIONS',
  UPDATE_ORGANIZATION_SUBSCRIPTION = 'UPDATE_ORGANIZATION_SUBSCRIPTION',

  // WORKSPACE
  CREATE_WORKSPACE = 'CREATE_WORKSPACE',
  ADD_WORKSPACE_MEMBERS = 'ADD_WORKSPACE_MEMBERS',
  REMOVE_WORKSPACE_MEMBER = 'REMOVE_WORKSPACE_MEMBER',
  CREATE_WORKSPACE_SUBSCRIPTION = 'CREATE_WORKSPACE_SUBSCRIPTION',
  REVOKE_INVITATION = 'REVOKE_INVITATION',

  // DEAL
  DEAL = 'DEAL',
  LIST_DEALS = 'LIST_DEALS',
  SEARCH_DEALS = 'SEARCH_DEALS',
  EDIT_REFERENCE = 'EDIT_REFERENCE',
  CANCEL_DEAL = 'CANCEL_DEAL',

  // ASSIGNMENT
  LIST_ASSIGNMENTS = 'LIST_ASSIGNMENTS',
  SEARCH_ASSIGNMENTS = 'SEARCH_ASSIGNMENTS',
  EDIT_ASSIGNMENT_CREATIVE_COMMENTS_FOR_ADMIN = 'EDIT_ASSIGNMENT_CREATIVE_COMMENTS_FOR_ADMIN',
  CREATIVE_ACCEPT_ASSIGNMENT = 'CREATIVE_ACCEPT_ASSIGNMENT',
  CREATIVE_DECLINE_ASSIGNMENT = 'CREATIVE_DECLINE_ASSIGNMENT',
  EXTEND_VIRTUAL_VISIT_HOSTING = 'EXTEND_VIRTUAL_VISIT_HOSTING',
  GET_UPSELL_PAYMENT_INTENT = 'GET_UPSELL_PAYMENT_INTENT',
  SELECT_VISUALS_FOR_STAGING = 'SELECT_VISUALS_FOR_STAGING',

  // SUBSCRIPTIONS
  GET_SUBSCRIPTION_CHECKOUT_LINK = 'GET_SUBSCRIPTION_CHECKOUT_LINK',

  // ORDER
  PLACE_ORDER = 'PLACE_ORDER',
  AUTOMATE_ORDER = 'AUTOMATE_ORDER',
  STOP_AUTOMATED_ORDER = 'STOP_AUTOMATED_ORDER',

  // VISUAL
  LOAD_VISUAL = 'LOAD_VISUAL',
  LOAD_PURCHASE_SESSION_VISUAL = 'LOAD_PURCHASE_FLOW_VISUAL',
  LIST_VISUALS = 'LIST_VISUALS',
  LIST_PURCHASE_SESSION_VISUALS = 'LIST_PURCHASE_FLOW_VISUALS',
  SELECT_VISUALS = 'SELECT_VISUALS',
  GET_ADDITIONAL_VISUAL_PRICE = 'GET_ADDITIONAL_VISUAL_PRICE',
  DOWNLOAD_ARCHIVE = 'DOWNLOAD_ARCHIVE',
  DELETE_VIRTUAL_VISIT = 'DELETE_VIRTUAL_VISIT',
  ARCHIVE_VISUALS = 'ARCHIVE_VISUALS',
  ARCHIVE_EDITING_VISUALS = 'ARCHIVE_EDITING_VISUALS',

  // VISUALS EDITING
  LOAD_WATERMARK_LOGO = 'LOAD_WATERMARK_LOGO',
  UPLOAD_WATERMARK_LOGO = 'UPLOAD_WATERMARK_LOGO',
  LOAD_EDITED_VISUAL = 'LOAD_EDITED_VISUAL',

  // ADMIN
  EDIT_ASSIGNMENT_ADMIN_COMMENTS_FOR_EDITOR = 'EDIT_ASSIGNMENT_ADMIN_COMMENTS_FOR_EDITOR',
  EDIT_ASSIGNMENT_INTERNAL_COMMENTS = 'EDIT_ASSIGNMENT_INTERNAL_COMMENTS',
  EDIT_ASSIGNMENT_CREATIVE_INSTRUCTIONS_FROM_ADMIN = 'EDIT_ASSIGNMENT_CREATIVE_INSTRUCTIONS_FROM_ADMIN',
  GET_TRANSLATION = 'GET_TRANSLATION',
  UPDATE_TRANSLATION = 'UPDATE_TRANSLATION',
}
