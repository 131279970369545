import { EntityKeys, QueryType } from 'utils/reactQuery'

import { AssignmentDocumentDTO } from 'models/assignment'
import { useAPI } from 'utils/API'
import { useQuery } from '@tanstack/react-query'

enum Endpoints {
  GET_ASSIGNMENT_DOCUMENTS = '/assignment/{assignmentId}/documents?type={type}',
}

enum DocumentType {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

// QUERIES
export function useGetAssignmentDocuments(assignmentId: string) {
  const api = useAPI<Endpoints>()

  return useQuery({
    queryKey: [EntityKeys.ASSIGNMENT, QueryType.GET, { assignmentId }],
    queryFn: () => api.get<AssignmentDocumentDTO[]>(
      Endpoints.GET_ASSIGNMENT_DOCUMENTS,
      {
        assignmentId: assignmentId.toString(),
        type: DocumentType.OUTPUT
      },
      true
    )
  })
}
