import { FIXED_DECIMAL_PLACES, bigFromFee } from 'utils/price'
import { InstructionCartItem, useCart } from './Cart.context'
import { ProductDetailsWithQuantityDTO, ProductListItemDTO, ProductQuantityDTO, TravelCostProduct } from 'models/purchaseFlow'
import { useCallback, useMemo } from 'react'

import { InstructionType } from 'constants/purchaseFlow'
import constate from 'constate'
import { isEditingCategory } from 'utils/validators'
import { usePurchaseFlowConfig } from './PurchaseFlowConfig.context'
import { usePurchaseFlowOrderMeta } from './PurchaseFlowOrderMeta.context'
import { usePurchaseFlowProducts } from './PurchaseFlowProducts.context'
import { useTranslation } from 'react-i18next'

export const [ProductAndInstructionListsContextProvider, useProductAndInstructionLists] = constate(() => {

  const { t } = useTranslation(['product'])
  const { selectedProducts, selectedKinds, selectedProductOptions } = usePurchaseFlowProducts()
  const { cartInstructionList } = useCart()
  const { allInstructionsByKind, selectedCategory } = usePurchaseFlowConfig()
  const { selectedPaymentMethod } = usePurchaseFlowOrderMeta()

  const selectedKindsSet = useMemo(() => new Set(selectedKinds || []), [selectedKinds])
  const selectedBillingProduct: ProductQuantityDTO | null = useMemo(() => {
    const selectedBillingProduct = selectedPaymentMethod ? allInstructionsByKind[selectedPaymentMethod] : undefined

    if (!selectedBillingProduct) return null

    return {
      id: selectedBillingProduct.id,
      count: 1,
      isOption: false,
    }
  }, [allInstructionsByKind, selectedPaymentMethod])

  const getProductsList = useCallback((fullItem: boolean = false, tracking: boolean = false): ProductQuantityDTO[] | ProductListItemDTO[] | ProductDetailsWithQuantityDTO[] => {
    if (!selectedProducts || !selectedCategory) return []
    const quantityList: ProductQuantityDTO[] = []
    const fullList: ProductListItemDTO[] = []
    const trackingList: ProductDetailsWithQuantityDTO[] = []

    for (const productKey in selectedProducts) {
      const product = selectedProducts[productKey]

      if (isEditingCategory(selectedCategory) && !selectedKindsSet.has(product.kind)) continue

      const productItem = {
        id: product.id,
        isOption: false,
        count: product.quantity,
        value: product.value,
      }

      if (fullItem) {
        fullList.push({
          ...productItem,
          name: t(`product:p_${product.id}`),
          pricePerItem: product.feePrice,
          comment: product.comment,
        })
      }

      if (tracking) trackingList.push({
        ...productItem,
        type: product.type,
        kind: product.kind,
        name: t(`product:p_${product.id}`),
        unitPrice: bigFromFee(product.feePrice).toFixed(FIXED_DECIMAL_PLACES),
      })

      else quantityList.push(productItem)

      const selectedProductOption = selectedProductOptions[product.id]

      for (let optionKey in selectedProductOption) {
        const option = selectedProductOption[optionKey]

        const optionItem = {
          id: option.id,
          count: option.quantity,
          isOption: true,
          type: product.type,
          kind: product.kind,
        }

        if (fullItem) {
          fullList.push({
            ...optionItem,
            name: t(`product:p_${option.id}`),
            pricePerItem: option.feePrice,
          })
        }
        else quantityList.push(optionItem)
      }

    }
    return fullItem ? fullList : (tracking ? trackingList : quantityList)
  }, [selectedCategory, selectedKindsSet, selectedProductOptions, selectedProducts, t])

  const getProductQuantityList = useCallback(() => getProductsList() as ProductQuantityDTO[], [getProductsList])
  const getProductFullList = useCallback(() => getProductsList(true) as ProductListItemDTO[], [getProductsList])
  const getProductDetailsWithQuantityList = useCallback(() => getProductsList(false, true) as ProductDetailsWithQuantityDTO[], [getProductsList])

  const toProductQuantity = (item: (TravelCostProduct | InstructionCartItem), isOption?: boolean): ProductQuantityDTO => {
    return ({
      id: 'productId' in item ? item.productId : item.id,
      count: 'productQuantity' in item ? item.productQuantity : 1,
      isOption: !!isOption,
    })
  }

  const toProductItem = useCallback((item: (TravelCostProduct | InstructionCartItem), isOption?: boolean): ProductListItemDTO => {
    const itemId = 'productId' in item ? item.productId : item.id

    return ({
      id: itemId,
      count: 'productQuantity' in item ? item.productQuantity : 1,
      isOption: !!isOption,
      name: t(`product:p_${itemId}`),
      pricePerItem: 'productUnitPrice' in item ? item.productUnitPrice : item.feePrice,
    })
  }, [t])

  const toTrackingItem = useCallback((item: (TravelCostProduct | InstructionCartItem), isOption?: boolean, type?: InstructionType): ProductDetailsWithQuantityDTO => {
    const itemId = 'productId' in item ? item.productId : item.id
    const feePrice = 'productUnitPrice' in item ? item.productUnitPrice : item.feePrice
    const kind = 'kind' in item ? item.kind : undefined

    return ({
      id: itemId,
      count: 'productQuantity' in item ? item.productQuantity : 1,
      isOption: !!isOption,
      kind,
      unitPrice: bigFromFee(feePrice).toFixed(FIXED_DECIMAL_PLACES),
      name: t(`product:p_${itemId}`),
      type,
    })
  }, [t])

  const getInstructionQuantityList = useCallback(() => {
    const list: ProductQuantityDTO[] = []

    for (const item of cartInstructionList) {
      list.push(toProductQuantity(item))
      if (!item.instructionProducts.length) continue
      const formattedExtraProducts = item.instructionProducts.map((option) => toProductQuantity(option, true))
      list.push(...formattedExtraProducts)
    }

    if (selectedBillingProduct) list.push({ ...selectedBillingProduct })

    return list
  }, [cartInstructionList, selectedBillingProduct])

  const getInstructionFullList = useCallback(() => {
    const list: ProductListItemDTO[] = []

    for (const item of cartInstructionList) {
      list.push(toProductItem(item))
      if (!item.instructionProducts.length) continue
      const formattedExtraProducts = item.instructionProducts.map((option) => toProductItem(option, true))
      list.push(...formattedExtraProducts)
    }

    return list
  }, [cartInstructionList, toProductItem])

  const getInstructionTrackingList = useCallback(() => {
    const list: ProductDetailsWithQuantityDTO[] = []

    for (const item of cartInstructionList) {
      list.push(toTrackingItem(item, false, item.type))
      if (!item.instructionProducts.length) continue
      const formattedExtraProducts = item.instructionProducts.map((option) => toTrackingItem(option, true, item.type))
      list.push(...formattedExtraProducts)
    }

    return list
  }, [cartInstructionList, toTrackingItem])

  return ({
    getProductQuantityList,
    getProductFullList,
    getProductDetailsWithQuantityList,
    getInstructionQuantityList,
    getInstructionFullList,
    getInstructionTrackingList,
  })
})
