import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { BEIGE_600, GRAY_100, GRAY_400, GRAY_500, GRAY_700, GRAY_800, GRAY_900, WHITE } from 'constants/styling/theme'
import { FileRejection, useDropzone } from 'react-dropzone'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import { UploadLimitError, UploadVisualItem } from '../UploadVisuals'
import { deletePurchaseSessionVisual, uploadPurchaseSessionVisual } from 'redux/Individual/Visual/LoadPurchaseSessionVisual'

import { APIRequestErrorType } from 'constants/API'
import { BorderBoxWrapper } from 'components/common/BorderBoxWrapper'
import Box from '@mui/material/Box'
import { CONST_PRODUCT_SERIAL } from 'constants/product'
import { CircleIcon } from 'components/common/CircleIcon'
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined'
import Collapse from '@mui/material/Collapse'
import Link from '@mui/material/Link'
import { PurchaseFlowProduct } from '../../_main/contexts/PurchaseFlowProducts.context'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { VisualFileType } from 'constants/visual'
import { acceptedProductDocumentMimetypes } from 'constants/misc'
import { useDispatch } from 'react-redux'
import { useFloorPlanConfig } from '../../steps/FloorPlanConfig/_main/FloorPlanConfigStep.context'
import { useFloorPlanLogo } from '../../steps/FloorPlanConfig/_main/FloorPlanLogo.context'
import { useImageRegex } from 'utils/hooks'
import { usePurchaseFlowConfig } from '../../_main/contexts/PurchaseFlowConfig.context'
import { useTargetOrderUser } from '../../_main/contexts'
import { useTranslation } from 'react-i18next'

/**
 * @interface Props for the FloorPlanLogoUpload component.
 */
interface Props {
  /** Product object */
  product: PurchaseFlowProduct
  /** Whether a file is being dragged over the browser window */
  isDraggingFile: boolean
}

/**
 * @component Component which displays floor plan upload
 * @example
 * <FloorPlanLogoUpload />
 */
export const FloorPlanLogoUpload: React.FC<Props> = ({
  product,
  isDraggingFile,
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['order'])
  const { adminSelectedUserEmail } = useTargetOrderUser()
  const { sessionId, selectedCategory } = usePurchaseFlowConfig()
  const {
    productId,
    downloadVisualsEntries,
    uploadVisualsEntries,
    downloadVisualsKeys,
    uploadVisualsOriginalFilenames,
    visualsCount,
    listedVisualKeys
  } = useFloorPlanLogo()
  const {
    companyLogo,
    setCompanyLogo,
    selectedTemplateId
  } = useFloorPlanConfig()

  if (!sessionId) throw new Error('Missing sessionId')

  const isDropzoneDisabled = useMemo(() => !!companyLogo, [companyLogo])

  const imageNameReplacePattern = useImageRegex(sessionId)

  const [fileSizeMap, setFileSizeMap] = useState<Record<string, number>>({})

  const sortFunction = useCallback((aKey: string, bKey: string) => {
    if (aKey > bKey) return 1
    else if (aKey < bKey) return -1
    else return 0
  }, [])

  const sortEntriesFunction = useCallback(([aKey, aObject]: [string, any], [bKey, bObject]: [string, any]) => {
    return sortFunction(aKey, bKey)
  }, [sortFunction])

  const onDrop = useCallback(async (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    if (fileRejections && fileRejections.length > 0) {
      if (fileRejections.length > 1) {
        return alert(`${t('step_product.unsupported_upload_max_1_file')}`)
      }

      const formats: string[] = []
      for (let fileRejection of fileRejections) {
        const split = fileRejection.file.name.split('.')
        if (split.length < 2) formats.push(fileRejection.file.name)
        else formats.push(split.slice(1).join('.'))
      }
      const formatsString = Array.from(new Set(formats).keys()).join(', ')
      alert(`${t('step_product.unsupported_file_format')}\n${formatsString}`)
    }

    const duplicateFiles = []
    for (let file of acceptedFiles) {
      if (!uploadVisualsOriginalFilenames.includes(file.name)) {
        setFileSizeMap((prev) => ({ ...prev, [file.name]: file.size }))
        setCompanyLogo(file.name)
        dispatch(uploadPurchaseSessionVisual(sessionId, productId, CONST_PRODUCT_SERIAL, file, adminSelectedUserEmail))
      } else duplicateFiles.push(file.name)
    }
    if (duplicateFiles.length > 0) {
      alert(t('step_product.duplicate_file_alert', { count: duplicateFiles.length, files: duplicateFiles.join(', ') }))
    }
  }, [t, uploadVisualsOriginalFilenames, setCompanyLogo, dispatch, sessionId, productId, adminSelectedUserEmail])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptedProductDocumentMimetypes,
    maxFiles: 1,
    disabled: isDropzoneDisabled
  })

  const onDeleteImage = useCallback((reactKey: string) => {
    setCompanyLogo(null)
    dispatch(deletePurchaseSessionVisual(sessionId, productId, CONST_PRODUCT_SERIAL, reactKey))
  }, [dispatch, productId, sessionId, setCompanyLogo])

  const preloadListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = listedVisualKeys.map(key => {
      const reactKey = key
      const fileName = key.replace(imageNameReplacePattern, '')
      return [key, reactKey, (
        <UploadVisualItem
          key={key}
          reactKey={key}
          type='preload'
          fileName={fileName}
          onDelete={onDeleteImage}
          size={fileSizeMap[fileName] ?? 0}
        />
      )]
    })

    return tupleArray
  }, [listedVisualKeys, imageNameReplacePattern, onDeleteImage, fileSizeMap])

  const downloadListing = useCallback(() => {
    const tupleArray: [string, string, JSX.Element][] = downloadVisualsEntries.map(([key, allTypes]) => {
      const img = allTypes?.[VisualFileType.RAW_THUMB]
      if (!img) throw new Error(`Image does not exist for key: ${key} and visual type: ${VisualFileType.RAW_THUMB}`)
      const isError = img.request.error_type !== APIRequestErrorType.NONE
      const label = img.file?.name.replace(imageNameReplacePattern, '') + (img.originalFilename ? ` | ${img.originalFilename}` : '')
      const reactKey = key

      return [key, reactKey, (
        <UploadVisualItem
          key={key}
          reactKey={key}
          type='download'
          image={img}
          label={label}
          isError={isError}
          onDelete={onDeleteImage}
          size={fileSizeMap[img.originalFilename || ''] ?? 0}
        />
      )]
    })

    return tupleArray
  }, [downloadVisualsEntries, imageNameReplacePattern, onDeleteImage, fileSizeMap])

  const uploadListing = useCallback(() => {
    const tupleArray: [string, string, string, JSX.Element][] = uploadVisualsEntries.map(([key, allTypes]) => {
      const img = allTypes?.[VisualFileType.RAW]
      if (!img) throw new Error(`Image does not exist for key: ${key} and visual type: ${VisualFileType.RAW}`)
      // For sorting fresh uploads on top
      const sortKey = (img.replaces || imageNameReplacePattern.exec(key)) ? key : `${sessionId}-bkbn-ZZZZZ-${img.droppedIn}-${key}`
      const reactKey = img.originalFilename ?? key
      const fileName = img.file ? img.file.name.replace(imageNameReplacePattern, '') : ''
      const originalFilename = img.originalFilename ? ` | ${img.originalFilename}` : ''
      const label = fileName ? fileName + originalFilename : originalFilename

      return [key, reactKey, sortKey, (
        <UploadVisualItem
          key={key}
          reactKey={key}
          image={img}
          type='uploading'
          label={label}
          onDelete={onDeleteImage}
          size={fileSizeMap[originalFilename || ''] ?? 0}
        />
      )]
    })

    return tupleArray
  }, [uploadVisualsEntries, imageNameReplacePattern, sessionId, onDeleteImage, fileSizeMap])

  const visualsListing = useCallback(() => {
    const map: Map<string, [string, JSX.Element]> = new Map()

    for (let [key, reactKey, node] of preloadListing()) map.set(key, [reactKey, node])
    for (let [key, reactKey, node] of downloadListing()) map.set(key, [reactKey, node])
    for (let [key, reactKey, sortKey, node] of uploadListing()) {
      map.delete(key)
      map.set(sortKey, [reactKey, node])
    }

    const entries: [string, [string, JSX.Element]][] = Array.from(map.entries())
    const sortedEntries = [...entries].sort(sortEntriesFunction)

    return (
      <Fragment>
        {sortedEntries.map(([key, [reactKey, node]]) => (
          <Fragment key={reactKey}>
            {node}
          </Fragment>
        ))}
      </Fragment>
    )
  }, [sortEntriesFunction, preloadListing, downloadListing, uploadListing])

  // Remove existing logo when select new template
  useEffect(() => {
    if (downloadVisualsKeys.length) {
      downloadVisualsKeys.forEach(key => {
        dispatch(deletePurchaseSessionVisual(sessionId, productId, CONST_PRODUCT_SERIAL, key))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplateId])

  useEffect(() => {
    if (visualsCount === 1) {
      logAnalyticsEvent(AnalyticsEvent.FLOOR_PLAN_LOGO_UPLOADED, { category: selectedCategory })
    }
  }, [selectedCategory, visualsCount])

  return (
    <BorderBoxWrapper elevation='sm' padding={2} borderColor={BEIGE_600}>

      {/** TITLE */}
      <Stack marginBottom={1} gap={0.5} alignItems="center" direction="row">
        <Typography variant='text-sm'>
          {t('step_product.upload_company_logo')}
        </Typography>
      </Stack>

      {/** UPLOAD ZONE */}
      <div {...getRootProps()}>
        <BorderBoxWrapper
          padding={4}
          elevation='none'
          textAlign="center"
          backgroundColor={isDropzoneDisabled ? GRAY_100 : undefined}
          sx={{ cursor: isDropzoneDisabled ? 'not-allowed' : 'pointer' }}
          border={`2px dashed ${GRAY_400}`}
        >

          {/** UPLOAD INPUT */}
          <input {...getInputProps()} />

          {/** SUBMIT & DROP */}
          <Stack gap={1} direction="column" alignItems="center">
            <CircleIcon
              size="5rem"
              icon={<CloudUploadOutlinedIcon fontSize="large" sx={{ color: isDropzoneDisabled ? GRAY_500 : GRAY_800 }} />}
              circleColor={isDropzoneDisabled ? WHITE : BEIGE_600}
            />

            {isDragActive || isDraggingFile
              ? (
                <Typography variant="text-sm" color={GRAY_900} fontWeight={500}>
                  {t('step_product.drop_here')}
                </Typography>
              )
              : (
                <Fragment>
                  <Box>
                    <Link variant="text-sm" color={GRAY_900} fontWeight={500}>
                      {t('step_product.upload_dropzone.click_upload')}
                    </Link>
                    <Typography variant="text-sm" color={GRAY_900}>
                      {` ${t('step_product.upload_dropzone.drag_n_drop')}`}
                    </Typography>
                  </Box>

                  <Typography variant="text-sm" color={GRAY_700}>
                    {t('step_product.upload_dropzone.suggestion')}
                  </Typography>
                </Fragment>
              )
            }
          </Stack>
        </BorderBoxWrapper>
      </div>

      {/** UPLOADED FLOOR PLAN LOGO */}
      {visualsCount > 0 && visualsListing()}

      {/** ERROR (visuals limit exceeded) */}
      <div>
        <Collapse in={downloadVisualsEntries.length > 1}>
          <UploadLimitError />
        </Collapse>
      </div>

    </BorderBoxWrapper>
  )
}
