import { useFlag } from '@unleash/proxy-client-react'
import { ClientGalleryController } from 'components/pages/Client/ClientGallery/_main/ClientGallery.controller'
import { CreativeGalleryController } from 'components/pages/Creative/CreativeGallery/_main/CreativeGallery.controller'
import { AdministratorGalleryController } from 'components/pages/Administrator/AdministratorGallery/_main/AdministratorGallery.controller'
import { GalleryControllerLegacy } from './GalleryLegacy.controller'
import { NoRoleGallery } from './NoRoleGallery'
import { useAuth0 } from 'utils/auth'
import { FeatureFlag } from 'utils/featureFlags'

/**
 * @component GalleryRoleSwitch to resolve paths and functions shared for all roles Galleries
 */
export const GalleryRoleSwitch: React.FC = () => {
  const allowSplitGalleries = useFlag(FeatureFlag.ALLOW_SPLIT_GALLERIES)
  const { roles } = useAuth0()

  if (roles.isClient) return <ClientGalleryController />
  else if (roles.isCreative && allowSplitGalleries) return <CreativeGalleryController />
  else if (roles.isAdmin && allowSplitGalleries) return <AdministratorGalleryController />

  return (
    <>
      {allowSplitGalleries ? <NoRoleGallery /> : <GalleryControllerLegacy /> }
    </>
  )
}
