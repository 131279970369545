import { AnalyticsEvent, logAnalyticsEvent } from 'utils/analytics'
import { CORAL_600, GRAY_700, GRAY_900 } from 'constants/styling/theme'
import { FloorPlanRenderType, useFloorPlanConfig } from '../_main/FloorPlanConfigStep.context'
import { Orientations, Styles, stylesMap } from '../FloorPlanPreview/floorPlan3DConstants'
import React, { ReactNode, useCallback, useMemo } from 'react'

import Box from '@mui/material/Box'
import { ColorThemePreview } from 'components/common/ColorThemePreview'
import { FloorPlanColors } from '../FloorPlanPreview/basicFloorPlanConstants'
import { Grid } from '@mui/material'
import { MUICheckCard } from 'components/common/MUICards'
import { PropertyType } from 'models/purchaseFlow'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { SelectableButtonsGrid } from 'components/common/SelectableButtonsGrid'
import { SelectableImageBox } from '../SelectableImageBox'
import Stack from '@mui/material/Stack'
import { ToggleFilterValue } from 'components/common/ToggleFilter'
import Typography from '@mui/material/Typography'
import { useFloorPlanTemplates } from '../_main'
import { usePurchaseFlowConfig } from 'components/pages/PurchaseFlow/_main/contexts'
import { useTranslation } from 'react-i18next'

const colorIcons: Record<FloorPlanColors, ReactNode> = {
  NEUTRAL: <ColorThemePreview mainColor="#9D9FA1" accentColor="#EFF0F0" />,
  BLACK: <ColorThemePreview mainColor="#252A2E" accentColor="white" />,
  BLUE: <ColorThemePreview mainColor="#274C78" accentColor="#E7ECEF" />,
  GREEN: <ColorThemePreview mainColor="#4D5D53" accentColor="#F4F4F4" />,
}

/**
 * @component
 * FloorPlanDesign displays the design options for floor plans, like color, fixtures and furnitures.
 * 
 * @example
 * <FloorPlanDesign />
 */
export const FloorPlanDesign: React.FC = () => {
  const { t } = useTranslation(['purchase_flow'])

  const {
    currentFloorPlanRenderType,
    selectedTemplateId,
    selectedColor,
    selectedStyle,
    selectedOrientation,
    selectedPropertyType,
    showFixtures,
    showFurniture,
    setSelectedColor,
    setSelectedStyle,
    setSelectedOrientation,
    setSelectedPropertyType,
    setShowFixtures,
    setShowFurniture,
  } = useFloorPlanConfig()

  const { selectedCategory } = usePurchaseFlowConfig()
  const { availableTemplates } = useFloorPlanTemplates()

  const handleSelectColor = useCallback((color: FloorPlanColors) => {
    setSelectedColor(color)
    logAnalyticsEvent(AnalyticsEvent.FLOOR_PLAN_COLOR_SELECTED, { color, category: selectedCategory })
  }, [selectedCategory, setSelectedColor])


  const formatOrientationsValues: ToggleFilterValue<Orientations>[] = useMemo(
    () => {
      const availableFormat = [Orientations.ANGLE_VIEW, Orientations.BIRD_VIEW]

      return availableFormat.map((format) => {
        return {
          value: format,
          displayName: t(`floor_plan_step.design.orientations.${format.toLowerCase()}`),
        }
      })
      // [t] is not a dependency because it is a constant function
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []
  )

  return (
    <SectionedBorderBox title={t('floor_plan_step.design.title')}>
      <Box display="flex" flexDirection="column" gap={1}>

        {currentFloorPlanRenderType === FloorPlanRenderType.RENDER_2D && (!selectedTemplateId || Object.values(FloorPlanColors).includes(availableTemplates[selectedTemplateId]?.config.style as FloorPlanColors)) &&
          <>
            {/** SELECT COLOR - 2D render only */}
            <Typography variant='text-sm' fontWeight={500} color={GRAY_700}>
              {t('floor_plan_step.design.color')}
            </Typography>

            {/** SELECT COLOR - 2D render only */}
            <Stack display="flex" flexDirection="row" flexWrap="wrap" gap={1}>
              {Object.keys(FloorPlanColors).map((value) =>
                <SelectableImageBox
                  key={value}
                  width={12.5}
                  height={8}
                  isSelected={selectedColor === value}
                  onSelect={() => handleSelectColor(FloorPlanColors[value as keyof typeof FloorPlanColors])}
                  image={colorIcons[value as keyof typeof FloorPlanColors]}
                  text={t(`floor_plan_step.design.colors.${value}`)}
                />
              )}
            </Stack>
          </>
        }

        {currentFloorPlanRenderType === FloorPlanRenderType.RENDER_3D &&
          <>
            {/** SELECT STYLE - 3D render only */}
            <Typography variant='text-sm' fontWeight={500} color={GRAY_700}>
              {t('floor_plan_step.design.style')}
            </Typography>

            {/** SELECT STYLE - 3D render only */}
            <Grid container spacing={1}>
              {Object.keys(Styles).map((value) => (
                <Grid item key={value}>
                  <SelectableImageBox
                    width={15.4}
                    height={14.8}
                    isSelected={selectedStyle === value}
                    onSelect={() => setSelectedStyle(Styles[value as keyof typeof Styles])}
                    image={stylesMap[value as keyof typeof Styles]}
                    text={t(`floor_plan_step.design.styles.${value}`)}
                  />
                </Grid>
              ))}
            </Grid>

            {/** SELECT ORIENTATION - 3D render only */}
            <Typography variant='text-sm' fontWeight={500} color={GRAY_700}>
              {t('floor_plan_step.design.orientation')}
            </Typography>

            <SelectableButtonsGrid
              activeButtonClickable
              values={formatOrientationsValues}
              selectedValue={selectedOrientation}
              onSelect={format => setSelectedOrientation(format)}
              onUnselect={format => setSelectedOrientation(format)}
            />
          </>
        }

        {/** SELECT PROPERTY (Commercial or Residential) */}
        <Typography variant='text-sm' fontWeight={500} color={GRAY_700}>
          {t('floor_plan_step.design.property_type')}
        </Typography>

        <MUICheckCard
          isHeaderClickable
          isRadio
          checked={selectedPropertyType === PropertyType.COMMERCIAL}
          onCheck={() => setSelectedPropertyType(PropertyType.COMMERCIAL)}
        >
          <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
            {t('floor_plan_step.design.commercial')}
          </Typography>
        </MUICheckCard>

        <MUICheckCard
          isHeaderClickable
          isRadio
          checked={selectedPropertyType === PropertyType.RESIDENTIAL}
          onCheck={() => setSelectedPropertyType(PropertyType.RESIDENTIAL)}
        >
          <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
            {t('floor_plan_step.design.residential')}
          </Typography>
        </MUICheckCard>

        {selectedPropertyType === null &&
          <Typography variant='text-xs' fontWeight={500} color={CORAL_600}>
            {t('floor_plan_step.design.property_type_error')}
          </Typography>
        }

        {/** SELECT ELEMENTS (Fixtures & Furnitures) */}
        <Typography variant='text-sm' fontWeight={500} color={GRAY_700}>
          {t('floor_plan_step.design.elements')}
        </Typography>

        <MUICheckCard
          isHeaderClickable
          checked={showFixtures}
          onCheck={() => setShowFixtures((prevState) => !prevState)}
        >
          <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
            {t('floor_plan_step.design.add_fixtures')}
          </Typography>
        </MUICheckCard>

        <MUICheckCard
          isHeaderClickable
          checked={showFurniture}
          onCheck={() => setShowFurniture((prevState) => !prevState)}
        >
          <Typography variant='text-md' fontWeight={600} color={GRAY_900}>
            {t('floor_plan_step.design.add_furnitures')}
          </Typography>
        </MUICheckCard>

      </Box>
    </SectionedBorderBox>
  )
}
