import { Nullable } from 'models/helpers'

/** Real estate property type */
export enum PropertyType {
  COMMERCIAL = 'COMMERCIAL',
  RESIDENTIAL = 'RESIDENTIAL'
}

/** Real estate property address */
export interface PropertyAddressDTO {
  /** Street name */
  street: string,
  /** Building number */
  streetNumber: string,
  /** Postal code */
  postalCode: string,
  /** City */
  city: string,
  /** Country */
  country: string,
  /** GEO coordinates of the address */
  coordinates?: {
    lat: number,
    lng: number,
  }
}

/** Real estate property DTO. */
export interface RealEstatePropertyDTO {
  /** Type of property - COMMERCIAL or RESIDENTIAL. */
  propertyType?: Nullable<PropertyType>,
  address?: Nullable<PropertyAddressDTO>
}
